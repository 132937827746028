import React from 'react';

const AdFrame = () => {
  return (
    <div id="frame" className="w-full">
      <iframe
        data-aa="2349108"
        src="//acceptable.a-ads.com/2349108"
        className="border-0 p-0 w-full h-full overflow-hidden bg-transparent"
        title="ad-frame"
      />
      <a
        className="block text-right text-xs"
        id="frame-link"
        href="https://aads.com/campaigns/new/?source_id=2349108&source_type=ad_unit&partner=2349108"
      >
        Advertise here
      </a>
    </div>
  );
};

export default AdFrame;
