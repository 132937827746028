import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../components/firebaseConfig';
import AuthPage from './AuthPage';

const withAuth = (Component) => {
  return (props) => {
    const [user, loading] = useAuthState(auth);

    if (loading) {
      return <p>Loading...</p>;
    }

    if (!user) {
      return <AuthPage />;
    }

    return <Component {...props} />;
  };
};

export default withAuth;
