import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { ESPContract, ESPABI } from './utils/constants';

const MintedNFTsList = ({ props }) => {
  const [nfts, setNFTs] = useState([]);
  const { provider, account } = props;

  useEffect(() => {
    const fetchNFTs = async () => {
      if (!provider || !account || !ESPContract) return;

      try {
        // Connect to the NFT contract using MetaMask provider
        const contract = new ethers.Contract(ESPContract, ESPABI, provider);

        // Fetch details of each minted NFT
        const nftPromises = [];
        nftPromises.push(await contract.getUserTokens(account));
        const NFTSList = await contract.getUserTokens(account)
        console.log(NFTSList.toString()) //async
        const nftIds = await Promise.all(nftPromises);

        // Fetch metadata URLs of each NFT
        const nftDataPromises = nftIds.map((id) => contract.uri(id.toString()));
        const nftDataUrls = await Promise.all(nftDataPromises);

        // Fetch metadata JSON from each URL
        const nftData = await Promise.all(
          nftDataUrls.map(async (url) => {
            const response = await fetch(url);
            return response.json();
          })
        );

        setNFTs(nftData);
      } catch (error) {
        console.error('Error fetching NFTs:', error);
      }
    };

    fetchNFTs();
  }, [provider, account, ESPContract]);

  const openOpenSea = (nftId) => {
    window.open(`https://opensea.io/assets/matic/${ESPContract}/${nftId}`, '_blank');
  };

  return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
        {nfts.map((nft, index) => (
          <div key={index} className="bg-gray-700 rounded-md overflow-hidden shadow-lg">
            <img
              src={nft.image}
              alt={`NFT ${index}`}
              className="w-full h-60 object-contain"
              style={{ aspectRatio: '1/1' }} // Ensure aspect ratio is maintained
            />
            <div className="p-4">
              <h3 className="text-lg font-semibold mb-2">{nft.name}</h3>
              <div className="flex text-center font-bold mb-4 space-x-4">
                <div className="w-28 h-24 flex items-center justify-center rounded-md bg-gray-500 p-2">
                  <p className="text-gray-300"><strong>Rarity:</strong> <br />{nft.attributes.find(attr => attr.trait_type === 'Rarity')?.value}</p>
                </div>
                <div className="h-24 flex items-center justify-center rounded-md bg-gray-500 p-2">
                  <p className="text-gray-300">Type: <br />{nft.attributes.find(attr => attr.trait_type === 'Type')?.value}</p>
                </div>
              </div>
              <button
                className="text-white bg-blue-500 w-full px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
                onClick={() => openOpenSea(nft.id)}
              >
                View on OpenSea
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default MintedNFTsList;
