import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-10">
      <div className="container mx-auto px-4">
        {/* Logo and Tagline */}
        <div className="flex flex-col md:flex-row items-center justify-between mb-8">
          <div className="flex items-center">
            <img src="/logo.png" alt="Dailycashloot Logo" className="h-10 mr-3" />
            <h3 className="text-sm md:text-lg font-bold">Dailycashloot - Task & Crypto</h3>
          </div>
        </div>

        {/* Links */}
        <div className="md:flex">
        <div className="flex md:flex-col justify-center md:justify-between">
        <div className="grid grid-cols-2 gap-6 mb-8">
          <div className="flex flex-col items-center md:items-start">
            <h4 className="text-lg font-semibold underline mb-2">About</h4>
            <a href="terms-and-conditions" className="text-sm hover:text-gray-400 mb-1">Terms of Service</a>
            <a href="privacy-policy" className="text-sm hover:text-gray-400 mb-1">Privacy Policy</a>
            <a href="#" className="text-sm hover:text-gray-400">Cookie Policy</a>
          </div>
          <div className="flex flex-col items-center md:items-start">
            <h4 className="text-lg font-semibold underline mb-2">Contact</h4>
            <a href="mailto:dailycashloot@gmail.com?subject=Partnership%20Inquiry" className="text-sm hover:text-gray-400 mb-1">Partnership</a>
            <a href="mailto:dailycashloot@gmail.com?subject=Collaboration%20Inquiry" className="text-sm hover:text-gray-400 mb-1">Collaboration</a>
            <a href="mailto:dailycashloot@gmail.com?subject=Contact%20Us" className="text-sm hover:text-gray-400">Contact Us</a>
          </div>
        </div>
        </div>
          <div className="flex flex-col items-center mb-8 md:items-center">
            <h4 className="text-lg font-semibold underline mb-2">Follow Us</h4>
            <div className="flex space-x-4">
              <a href="https://x.com/dailycashloot" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://t.me/dailycashlootofficial" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <i className="fab fa-telegram"></i>
              </a>
              <a href="https://instagram.com/dailycashlootofficial" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>  
          </div>
        {/* Disclaimers */}
        <div className="text-center mb-8">
          <p className="text-sm text-gray-400 mb-4">
            Disclaimer: The information provided on this site is for general educational purposes only and is not intended to constitute investment or other advice on financial products. Dailycashloot makes no representations as to the accuracy or validity of any information on this site and will not be liable for any errors or delays. Links to third-party sites are for informational purposes and are not under Dailycashloot's control.
          </p>
        </div>

        {/* Footer Copyright */}
        <div className="text-center">
          <p className=" text-gray-500 mb-2">Made with 💖 in 🇮🇳 India</p>
          <p className="text-sm text-gray-500">© {new Date().getFullYear()} Dailycashloot. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
