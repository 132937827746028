import React, { useEffect, useState } from 'react';
import { ref, onValue, update } from 'firebase/database';
import { analytics, auth, database } from '../components/firebaseConfig';
import { Link, useNavigate } from 'react-router-dom';
import Chipview from '../components/Chipview';
import { logEvent } from 'firebase/analytics';
import { Helmet } from 'react-helmet-async';
import { signOut } from 'firebase/auth';

const ProjectListingPage = () => {
  const [projects, setProjects] = useState([]);
  const [activeTab, setActiveTab] = useState('ongoing');
  const [dropdownOpen, setDropdownOpen] = useState({signOut:false})
  const toggleList = (menu) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };
  const [loading, setLoading] = useState(true);
  const {currentUser} = auth
  const [likedProjects, setLikedProjects] = useState(() => {
    // Initialize likedProjects from localStorage if available
    const storedLikes = localStorage.getItem('likedProjects');
    return storedLikes ? JSON.parse(storedLikes) : [];
  });
  const navigate = useNavigate();
  const metaTitle = "Dailycashloot Crypto Projects Launchpad - Discover Ongoing, Upcoming, and Ended Projects";
  const metaDescription = "Discover the latest in crypto innovation with our launchpad. Explore ongoing, upcoming, and ended projects. Join us in shaping the future of blockchain technology.";
  const metaKeywords = [
    "Crypto Projects Launchpad",
    "Dailycashloot Task & Crypto",
    "Ongoing Crypto Projects",
    "Upcoming Crypto Projects",
    "Ended Crypto Projects",
    "Blockchain Innovation",
    "Token Launchpad",
    "Blockchain Projects",
    "Crypto Startup Launchpad",
    "Crypto Venture Capital",
    "Blockchain Investments",
    "Token Sale Platform",
    "Crypto Project Showcase",
    "Blockchain Technology Showcase"
  ].join(", ");
  let Pagetitle = "No projects found"
  let Pagedescription = "Check back later for more projects!"

  logEvent(analytics, 'View Projects', {
    firebase_screen: 'Projects Page',
    firebase_screen_class: 'projects'
  });

  function submit(projectId) {
    navigate(`/projects/${projectId}`);
  }

  useEffect(() => {
    const projectsRef = ref(database, 'projects');
    onValue(projectsRef, (snapshot) => {
      const data = snapshot.val();
      const projectsList = data ? Object.entries(data).map(([id, project]) => ({ id, ...project })) : [];
      setProjects(projectsList);
      setLoading(false); // Set loading to false once data is fetched
    });
  }, []);

  const filteredProjects = projects.filter((project) => {
    if (activeTab === 'ongoing'){
      Pagetitle = "No Ongoing projects found"
      Pagedescription = "Check back later for more projects! or Suggest a Better Project to us !!!"
      return project?.status === 'ongoing';
    }
    if (activeTab === 'upcoming'){
      Pagetitle = "No Upcoming projects found"
      Pagedescription = "Currently there is No Upcoming Projects Found. Check back later for more projects!"
      return project?.status === 'upcoming';
    } 
    if (activeTab === 'ended'){
      Pagetitle = "No Ended projects found"
      Pagedescription = "Check back later for more projects!"
      return project?.status === 'ended';
    } 
    return project;
  });

  // Function to toggle liking of a project
  const toggleLike = (projectId) => {
    if (likedProjects.includes(projectId)) {
      // Unlike the project
      const updatedLikedProjects = likedProjects.filter(id => id !== projectId);
      setLikedProjects(updatedLikedProjects);
      const projectRef = ref(database, `projects/${projectId}`);
      update(projectRef, { likes: projects.find(project => project?.id === projectId).likes - 1 });
      localStorage.setItem('likedProjects', JSON.stringify(updatedLikedProjects));
    } else {
      // Like the project
      const updatedLikedProjects = [...likedProjects, projectId];
      setLikedProjects(updatedLikedProjects);
      const projectRef = ref(database, `projects/${projectId}`);
    update(projectRef, { likes: projects.find(project => project?.id === projectId).likes + 1 });
      localStorage.setItem('likedProjects', JSON.stringify(updatedLikedProjects)); // Update local storage
    }
  };

  return (
    <div className="p-2 bg-gray-100 text-black min-h-screen text-sm font-sans ">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        {/* Other meta tags as needed */}
      </Helmet>
      <div className="container mx-auto">
      <div className='md:flex md:justify-between md:items-center lg:flex my-4'>
      <div className='flex justify-center items-center text-center'>
        <h1 className="text-3xl font-bold mb-4">
          Projects Launchpad
        </h1>
        <div>
          <p className='ml-4 font-bold border-blue-600 border-collapse mb-3 bg-blue-100 px-4 text-blue-600 rounded-full'>
            Beta
          </p>
        </div>
      </div>
      <div className='flex justify-between mb-4'>
        <Link to="/submit" className="mr-4 text-white bg-blue-500 px-4 py-2 rounded-xl hover:bg-blue-600 font-bold">
          Submit a Project
        </Link>
        <div className='relative'>
          <button 
            onClick={() => toggleList('tasks')} 
            className='flex items-center shadow-lg bg-blue-500 hover:bg-blue-600 transition duration-300 font-bold font-sans text-white px-4 py-2 rounded-xl'
          >
            <img className='rounded-full h-6 w-6' src={currentUser?.photoURL} alt='userLogo'/>
            <p className='ml-2'>{currentUser?.displayName}</p>
          </button>
          {dropdownOpen.tasks && (
            <div className='absolute right-0 mt-2 bg-white text-gray-900 px-8 py-2 rounded-xl shadow-lg'>
              <button 
                className='w-full text-left text-sm font-sans font-bold'
                onClick={() => {
                  signOut(auth);
                  setDropdownOpen(false);
                }}
              >
                Sign Out
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
        

        
        {/* Tabs */}
        <div className="flex mb-4">
          <button
            onClick={() => setActiveTab('ongoing')}
            className={`px-4 py-2 rounded-t-md ${activeTab === 'ongoing' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            Ongoing
          </button>
          <button
            onClick={() => setActiveTab('upcoming')}
            className={`ml-2 px-4 py-2 rounded-t-md ${activeTab === 'upcoming' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            Upcoming
          </button>
          <button
            onClick={() => setActiveTab('ended')}
            className={`ml-2 px-4 py-2 rounded-t-md ${activeTab === 'ended' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            Ended
          </button>
        </div>

        {/* Loading state */}
        {loading ? (
          <div className="flex items-center justify-center h-screen">
            <p className="text-3xl">Loading...</p>
          </div>
        ) : (
          // Render projects based on active tab
          filteredProjects.length === 0 ? (
            <div className="flex items-center justify-center text-center h-screen flex-col">
              <p className="md:text-3xl font-bold mb-4">{Pagetitle}<span role="img" aria-label="Sad emoji">😔</span></p>
              <p className="md:text-xl"> {Pagedescription} </p>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredProjects.filter(project => project?.approved).map((project) => (
                <div key={project?.id} className="bg-white p-4 rounded-xl shadow-md flex-col items-start">
                  <div className="flex items-center mb-4">
                    <div className="rounded-full overflow-hidden mr-4 flex-shrink-0" style={{ width: '80px', height: '80px' }}>
                      <img draggable="false" src={project?.imageUrl} alt={project?.name} className="w-full h-full object-cover rounded-full" />
                    </div>
                    <div>
                      <h2 className="flex text-2xl font-bold">{project?.name} {project?.verified && <img draggable="false" className="h-8 w-8 ml-2" src={`${process.env.PUBLIC_URL}/verifiedicon.png`} alt="Verified" />}</h2>
                      {project?.new && <div className='flex justify-center w-16 rounded-full bg-green-400 text-xs text-white px-2 py-1 mt-2'>New ✨</div>}
                    </div>
                  </div>
                  
                  <div>
                    <div className="text-sm mb-2 font-semibold"><Chipview categories={project?.category}/></div>
                  </div>
                  <p className="text-black text-sm text-ellipsis line-clamp-4">{project?.description}</p>
                  <div className="grid grid-cols-1 gap-2 w-full"></div>
                  <div className='flex text-right justify-between items-center' >
                    <button onClick={() => submit(project?.id)} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600">View Details</button>
                    <div className='flex justify-center items-center'>
                    <button 
                      onClick={() => toggleLike(project?.id)} 
                      className={` ${likedProjects.includes(project?.id) ? 'text-red-500' : 'text-gray-500'} text-xl focus:outline-none`}
                      >
                      {likedProjects.includes(project?.id) ? '❤️' : '🤍'}
                    </button>
                      <p className='font-bold text-xl mr-4'>{project?.likes}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ProjectListingPage;
