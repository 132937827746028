import React from 'react';
import { FaTasks, FaGift, FaRocket, FaPaintBrush, FaFire, FaPaperPlane, FaDonate, FaMoneyBillWave, FaGamepad, FaLaptopCode } from 'react-icons/fa';

const features = [
  {
    title: "Task & Earn",
    description: "Earn DCL CROWN tokens by completing tasks.",
    icon: <FaTasks className="text-3xl" />,
  },
  {
    title: "Airdrop Provider",
    description: "Claim free tokens from the latest airdrops.",
    icon: <FaGift className="text-3xl" />,
  },
  {
    title: "LaunchPad",
    description: "Support and launch new crypto projects.",
    icon: <FaRocket className="text-3xl" />,
  },
  {
    title: "NFT Mint",
    description: "Create and mint NFTs easily.",
    icon: <FaPaintBrush className="text-3xl" />,
  },
  {
    title: "Trending DApps",
    description: "Explore the hottest decentralized apps.",
    icon: <FaFire className="text-3xl" />,
  },
  {
    title: "Submit Projects",
    description: "Showcase your projects and gain support.",
    icon: <FaPaperPlane className="text-3xl" />,
  },
  {
    title: "Donate to Startups",
    description: "Support startups with DCL CROWN tokens.",
    icon: <FaDonate className="text-3xl" />,
  },
  {
    title: "Fundraising",
    description: "Raise funds and connect with investors.",
    icon: <FaMoneyBillWave className="text-3xl" />,
  },
  {
    title: "DCL Kingdom Game",
    description: "Play and earn tokens in our game.",
    icon: <FaGamepad className="text-3xl" />,
  },
  {
    title: "Developer Incentives",
    description: "Earn tokens for listing your apps.",
    icon: <FaLaptopCode className="text-3xl" />,
  },
];

const KeyFeatures = () => {
  return (
    <div className="py-8 bg-gray-100">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="lg:text-center">
          <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Key Features</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Discover What We Offer
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Explore the unique features and opportunities available on Dailycashloot.
          </p>
        </div>

        <div className="mt-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {features.map((feature, index) => (
              <div key={index} className="relative p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
                <div className="flex items-center justify-center h-12 w-12 rounded-full bg-indigo-500 text-white mb-4">
                  {feature.icon}
                </div>
                <h3 className="text-lg font-semibold text-gray-900">{feature.title}</h3>
                <p className="mt-2 text-sm text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyFeatures;
