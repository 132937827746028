// NumberIncreaseAnimation.js
import React from 'react';
import CountUp from 'react-countup';

const NumberIncreaseAnimation = ({
  start = 0,
  end = 100,
  duration = 2.5,
  prefix = '',
  suffix = '',
  decimals=undefined
}) => {
  return (
    <div className="font-bold">
      <CountUp
        decimals={decimals}
        start={start}
        end={end}
        duration={duration}
        prefix={prefix}
        suffix={suffix}
        separator=","
      />
    </div>
  );
};

export default NumberIncreaseAnimation;
