import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { ESPContract } from "./utils/constants";

const Navbar = ({ props }) => {
  const nav = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({
    dashboard: false,
    nftMint: false,
    projects: false,
    docs: false,
    tasks: false,
    recharge:false,
  });
  const { account, requestAccounts, connecting, wallet } = props;

  const openOpenSea = () => {
    window.open(`https://opensea.io/assets/matic/${ESPContract}`, '_blank');
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleConnectWallet = async () => {
    await requestAccounts();
  };

  const toggleDropdown = (menu, open) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [menu]: open,
    }));
  };

  const toggleList = (menu) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  return (
    <nav className="bg-gradient-to-bl px-4 from-indigo-950 to-blue-600 py-4 pr-2">
      <div className="flex justify-between items-center text-sm font-sans font-bold">
        <div className="flex items-center">
          <img 
            draggable="false"
            className="flex h-auto w-10 mx-4"
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="DailycashlootLogo"
          />
          <div className="flex-none justify-items-start text-white text-xs ">
            <p className="flex font-bold">DailyCashLoot - Task & Crypto</p>
            <p className="flex text-xs">Earning Crypto Made Simple ✨</p>
          </div>
        </div>
        <div className="flex md:hidden ">
          <button className="text-white focus:outline-none" onClick={toggleNavbar}>
            {isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            )}
          </button>
        </div>
        <div className="hidden md:flex flex-grow justify-end">
          {/* // for Wallet connect */}
          
          <div
            className="relative group"
            onMouseEnter={() => toggleDropdown("dashboard", true)}
            onMouseLeave={() => toggleDropdown("dashboard", false)}
          >
            <Link
              to="/"
              className="text-white hover:text-gray-300 px-4 py-2 rounded-md flex items-center"
            >
              Dashboard
            </Link>
            
          </div>
          <div
            className="relative group"
            onMouseEnter={() => toggleDropdown("tasks", true)}
            onMouseLeave={() => toggleDropdown("tasks", false)}
          >
            <Link
              to="/tasks"
              className="text-white hover:text-gray-300 pr-4 py-2 rounded-md flex items-center"
            >
              Task & Earn
            </Link>
            
          </div>
          {/* <div
            className="relative group"
            onMouseEnter={() => toggleDropdown("recharge", true)}
            onMouseLeave={() => toggleDropdown("recharge", false)}
          >
            <Link
              to="/cryptorecharge"
              className="text-white hover:text-gray-300 pr-4 py-2 rounded-md flex items-center"
            >
              Mobile Recharge
            </Link>
            
          </div> */}
          <div
            className="relative group"
            onMouseEnter={() => toggleDropdown("nftMint", true)}
            onMouseLeave={() => toggleDropdown("nftMint", false)}
          >
            <Link
              to="/EarlySupporterPass"
              className="text-white hover:text-gray-300 pr-4 rounded-md flex items-center mt-2"
            >
              NFT Pass<p className="ml-2">
              {dropdownOpen.nftMint ? <FiChevronUp /> : <FiChevronDown />}
              </p>
            </Link>
            {dropdownOpen.nftMint && (
              <div className="absolute z-10 bg-white shadow-lg rounded-md py-2 mt-2">
                <button
                onClick={openOpenSea}
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Trade on Opensea
                </button>
                <Link
                  to="/EarlySupporterPass"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  EarlySupporterNFT Mint
                </Link>
              </div>
            )}
          </div>
          <div
            className="relative group"
            onMouseEnter={() => toggleDropdown("projects", true)}
            onMouseLeave={() => toggleDropdown("projects", false)}
          >
            <Link
              to="/projects"
              className="text-white hover:text-gray-300 pr-4 mt-2 rounded-md flex items-center"
            >
              Projects LaunchPad<p className="ml-2">
              {dropdownOpen.projects ? <FiChevronUp /> : <FiChevronDown />}
              </p>
            </Link>
            {dropdownOpen.projects && (
              <div className="absolute z-10 bg-white shadow-lg rounded-md py-2 mt-2">
                <Link
                  to="/projects"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  View Projects
                </Link>
                <Link
                  to="/submit"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Submit Project
                </Link>
              </div>
            )}
          </div>
          <div
            className="relative group"
            onMouseEnter={() => toggleDropdown("docs", true)}
            onMouseLeave={() => toggleDropdown("docs", false)}
          >
            <a
              href="https://docs.dailycashloot.fun"
              className="text-white hover:text-gray-300 block py-2 mr-8"
              target="_blank"
              rel="noopener noreferrer"
            >
              Whitepaper
            </a>
            
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden mt-4 flex flex-col items-center">
          <div
            className="w-full"
            onClick={() => toggleList("dashboard")}
          >
            <Link
              to="/"
              className="text-white hover:text-gray-300 block py-2 items-center justify-between"
            >
              Dashboard 
              {/* {dropdownOpen.dashboard ? <FiChevronUp /> : <FiChevronDown />} */}
            </Link>
            {/* {dropdownOpen.dashboard && (
              <div className="flex flex-col items-start w-full">
                <Link
                  to="/sub1"
                  className="text-white hover:text-gray-300 block py-2 pl-4"
                >
                  Submenu 1
                </Link>
                <Link
                  to="/sub2"
                  className="text-white hover:text-gray-300 block py-2 pl-4"
                >
                  Submenu 2
                </Link>
              </div>
            )} */}
          </div>
          <div
            className="w-full"
            onClick={() => toggleList("tasks")}
          >
            <Link
              to="/tasks"
              className="text-white hover:text-gray-300 block py-2 items-center justify-between"
            >
              Task & Earn 
              {/* {dropdownOpen.dashboard ? <FiChevronUp /> : <FiChevronDown />} */}
            </Link>
            {/* {dropdownOpen.dashboard && (
              <div className="flex flex-col items-start w-full">
                <Link
                  to="/sub1"
                  className="text-white hover:text-gray-300 block py-2 pl-4"
                >
                  Submenu 1
                </Link>
                <Link
                  to="/sub2"
                  className="text-white hover:text-gray-300 block py-2 pl-4"
                >
                  Submenu 2
                </Link>
              </div>
            )} */}
          </div>
          {/* <div
            className="w-full"
            onClick={() => toggleList("tasks")}
          >
            <Link
              to="/cryptorecharge"
              className="text-white hover:text-gray-300 block py-2 items-center justify-between"
            >
              Mobile Recharge
            </Link>
            
          </div> */}
          <div
            className="w-full"
            onClick={() => toggleList("nftMint")}
          >
            <Link
              to="/EarlySupporterPass"
              className="text-white hover:text-gray-300 block py-2 flex items-center justify-between"
            >
              NFT Pass {dropdownOpen.nftMint ? <FiChevronUp /> : <FiChevronDown />}
            </Link>
            {dropdownOpen.nftMint && (
              <div className="flex flex-col items-start w-full">
                <button
                onClick={openOpenSea}
                  className="text-white hover:text-gray-300 block py-2 pl-4"
                >
                  Trade on Opensea
                </button>
                <Link
                  to="/EarlySupporterPass"
                  className="text-white hover:text-gray-300 block py-2 pl-4"
                >
                 EarlySupporterNFT Mint
                </Link>
              </div>
            )}
          </div>
          <div
            className="w-full"
            onClick={() => toggleList("projects")}
          >
            <Link
              to="/projects"
              className="text-white hover:text-gray-300 block py-2 flex items-center justify-between"
            >
              Projects LaunchPad {dropdownOpen.projects ? <FiChevronUp /> : <FiChevronDown />}
            </Link>
            {dropdownOpen.projects && (
              <div className="flex flex-col items-start w-full">
                <Link
                  to="/projects"
                  className="text-white hover:text-gray-300 block py-2 pl-4"
                >
                  View Projects
                </Link>
                <Link
                  to="/submit"
                  className="text-white hover:text-gray-300 block py-2 pl-4"
                >
                  Submit Project
                </Link>
              </div>
            )}
          </div>
          {/* <Link
              to="/tasks"
              className="text-white hover:text-gray-300 block py-2 flex items-left justify-between"
            >
              Task & Crypto
              {dropdownOpen.projects ? <FiChevronUp /> : <FiChevronDown />}
          </Link> */}
          <div
            className="w-full"
            onClick={() => toggleList("docs")}
          >
            <a
              href="https://docs.dailycashloot.fun"
              className="text-white hover:text-gray-300 flex py-2 items-center justify-between"
              target="_blank"
              rel="noopener noreferrer"
            >
             Whitepaper
            </a>
            
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
