import React from 'react'

const NotFound = () => {
  return (<div className='flex flex-col min-h-screen justify-center items-center bg-gray-100'>
      <strong className='text-5xl font-bold text-black '>404</strong>
      <p className='mt-2 text-black'>
        Page not Found
      </p>
  </div>
  )
}

export default NotFound