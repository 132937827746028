import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth, updateUser } from '../components/firebaseConfig';
import logo from '../logo.png';
import { useAuth } from './AuthContext';
import { getAdditionalUserInfo } from 'firebase/auth';
import { getDatabase, ref, update } from 'firebase/database';

const AuthPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const db = getDatabase();
  const { signInWithGoogle, googleError } = useAuth();
  const [referrerUID, setReferrerUID] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const redirectTo = queryParams.get('redirect') || '/tasks';
    const referrer = queryParams.get('referrer');

    if (referrer) {
      setReferrerUID(referrer);
    }

    if (auth?.currentUser) {
      navigate(redirectTo);
    }
  }, [location, navigate]);

  const handleGoogleAuth = async () => {
    await signInWithGoogle()
      .then(async (result) => {
        const isNewUser = getAdditionalUserInfo(result).isNewUser;
        if (isNewUser) {
          const joined = new Date().toISOString();
          const userData = {
            email: result.user.email || null,
            displayName: result.user.displayName || null,
            logo: result.user.photoURL || null,
            phone: result.user.phoneNumber || null,
            uid: result.user.uid || null,
            points: 100,
            referrer: referrerUID || null,
            joined: joined || null,
          };
          await updateUser(result?.user?.uid, userData);
          const historyref = ref(db, `pointshistory/${result.user.uid}`)
          const now2 = new Date().toISOString();
          update(historyref, 
            [{ date: now2, points: 100, description: 'SignUp Reward' }]
          )
        }
        const queryParams = new URLSearchParams(location.search);
        const redirectTo = queryParams.get('redirect') || '/tasks';
        navigate(redirectTo);
      })
      .catch((err) => {
        console.error('Google Authentication Error:', err.message);
        console.error('Google Authentication Error:', getErrorMessage(err));
      });
  };

  const getErrorMessage = (error) => {
    switch (error?.code) {
      case 'auth/email-already-in-use':
        return 'This email is already in use. Please use a different email or sign in.';
      case 'auth/invalid-email':
        return 'The email address is invalid. Please enter a valid email address.';
      case 'auth/wrong-password':
        return 'The password is incorrect. Please try again.';
      case 'auth/user-not-found':
        return 'No account found with this email. Please sign up.';
      case 'auth/weak-password':
        return 'The password is too weak. Please use a stronger password.';
      default:
        return error?.message || 'An unknown error occurred. Please try again.';
    }
  };

  return (
    <div className="bg-gradient-to-bl from-indigo-950 to-blue-600 min-h-screen flex flex-col items-center justify-center p-6">
      <img src={logo} alt="Dailycashloot Logo" className="h-20 w-20 md:h-24 md:w-24 lg:h-28 lg:w-28 animate-bounce" />

      <h1 className="text-xl md:text-3xl lg:text-4xl font-extrabold text-white mb-2 text-center">
        👋 Welcome to Dailycashloot
      </h1>
      <p className="text-base md:text-lg lg:text-xl text-white mb-6 text-center">
        Dive into the latest crypto airdrops, task & earn opportunities, and more!
      </p>

      <button
        onClick={handleGoogleAuth}
        className="bg-white text-purple-700 font-semibold text-base md:text-lg lg:text-xl px-6 py-3 rounded-full shadow-lg hover:shadow-2xl transition-transform transform hover:scale-110 flex items-center gap-3"
      >
        <img src="https://img.icons8.com/color/24/000000/google-logo.png" alt="Google Icon" className="h-6 w-6" />
        Sign In with Google
      </button>

      {googleError && (
        <div className="text-red-200 mt-4 text-center font-semibold text-sm md:text-base">
          {getErrorMessage(googleError)}
        </div>
      )}

      <div className="mt-8 text-center text-white text-xs md:text-sm lg:text-base">
        <p>
          By signing in, you agree to our{' '}
          <a href="/terms-and-conditions" className="underline">
            Terms & Conditions
          </a>{' '}
          and{' '}
          <a href="/privacy-policy" className="underline">
            Privacy Policy
          </a>.
        </p>
      </div>
    </div>
  );
};

export default AuthPage;
