import React, { useEffect, useState } from 'react';
import NumberIncreaseAnimation from '../components/NumberIncreaseAnimation';
import { getAuth } from 'firebase/auth';
import { getDatabase, onValue, ref, update } from 'firebase/database';
import CountdownTimer from '../pages/CountdownTimer'

const Leaderboard = ({ users }) => {
  // Generate leaderboard data by calculating referrals and tasks completed for each user
  const leaderboardData = Object.values(users).map((user) => {
    const referralCount = Object.values(users).filter((u) => u?.referrer === user?.uid).length;
    const tasksCompleted = Object.values(user?.tasks || {}).length;
    return {
      ...user,
      referralCount,
      tasksCompleted,
    };
  });
  const [rewards, setRewards] = useState({});
  const [rewardEndTime, setRewardEndTime] = useState(null);
  const Admin = process.env.REACT_APP_EMAIL;

  
  useEffect(() => {
    const db = getDatabase();
    const rewardsRef = ref(db, 'rewards/');
    
    onValue(rewardsRef, (snapshot) => {
      const data = snapshot.val();
      setRewards(snapshot.val());
      if (data && data?.rewardEndTime) {
        setRewardEndTime(data?.rewardEndTime);
      }
    });
  }, []);
  const {currentUser} = getAuth()
  // Find the current user's data
  const currentUserMap = leaderboardData.find(user => user?.uid === currentUser?.uid);

  // Get top 10 users
  const topUsers = leaderboardData
    .sort((a, b) => b.points - a.points)
    .slice(0, 10);

  const rewardUsers = (user, amount) => {
    const db = getDatabase()
    const userRef = ref(db,`users/${user?.uid}/`)
    let balance = user?.toncoin || 0
    let updatedBalance = balance+amount
    update(userRef,{toncoin:updatedBalance,prevTon:balance})
  }

  return (
    <div className="leaderboard-container max-w-6xl mx-auto p-6">
      <h2 className="text-3xl font-bold text-white mb-6 text-center">Leaderboard</h2>

      {/* Countdown Timer for Rewards */}
      {rewardEndTime && (
        <div className="mb-6">
          <h3 className="text-2xl font-bold text-center text-green-400">Time Left for Rewards:</h3>
          <CountdownTimer endTime={rewardEndTime} />
        </div>
      )}

      {/* Display top 10 users */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
        {topUsers.map((user, index) => (
          <div
            key={user?.uid}
            className="bg-gray-800 text-white rounded-lg shadow-lg p-4 flex items-center"
          >
            <p className="text-gray-400 font-extrabold mr-2">#{index + 1}</p>
            <div className="w-10 h-10 rounded-full overflow-hidden mr-4">
              <img src={user?.logo} alt={user?.displayName} className="w-full h-full object-cover" />
            </div>
            <div className="flex-1">
              <h3 className="text-base font-bold mr-2 text text-clip">{user?.displayName}</h3>
            <span className="text-base font-extrabold text-green-500">
              <NumberIncreaseAnimation start={0} end={user?.points} duration={10} suffix=" CP" />
            </span>
            </div>
            {index < 3 && rewards && (
                <div className="flex text-sm text-gray-400">
                  <p className='text-white font-bold'>{rewards[`top${index + 1}`]?.tonReward || 0}
                  </p>
                  <img src={'/ton_logo.png'} alt='ton_logo' className='h-4 ml-1 mt-0.5'/>
                  {currentUser.email === Admin ? <button onClick={()=>rewardUsers(user, rewards[`top${index + 1}`]?.tonReward)} className='bg-blue-600 text-xs text-nowrap ml-4 hover:bg-blue-500 transition duration-300 md:text-sm px-4 md:px-8 rounded-xl py-2' >
              Distribute
              </button> : ''}
                </div>
              )}
          </div>
        ))}
      </div>

      {/* Display current user's rank and points */}
      <h2 className="text-xl font-bold text-white mb-6 text-center">Current Ranking</h2>
      {currentUserMap && (
        <div
          className="bg-gray-800 text-white rounded-lg shadow-lg p-4 flex items-center"
        >
          <p className="text-gray-400 font-extrabold mr-2">
            #{leaderboardData.indexOf(currentUserMap) + 1}
          </p>
          <div className="w-10 h-10 rounded-full overflow-hidden mr-4">
            <img src={currentUserMap?.logo} alt={currentUserMap?.displayName} className="w-full h-full object-cover" />
          </div>
          <div className="flex-1">
            <h3 className="text-base font-bold mr-2">{currentUserMap?.displayName}</h3>
          <span className="text-base font-extrabold text-green-500">
            <NumberIncreaseAnimation start={0} end={currentUserMap?.points} duration={10} suffix=" CP" />
          </span>
          </div>
          {leaderboardData.indexOf(currentUserMap) < 3 && rewards && (
                <div className="flex text-sm text-white font-bold">
                  <p>{rewards[`top${leaderboardData.indexOf(currentUserMap) + 1}`]?.tonReward || 0}</p>
                  <img src={'/ton_logo.png'} alt='ton_logo' className='h-4 ml-1 mt-0.5'/>
                </div>
              )}
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
