import React, {useState, useEffect} from 'react';
import { analytics } from './firebaseConfig';
import { logEvent } from 'firebase/analytics';
import {ESPContract, ESPABI} from './utils/constants'
import MintedNFTsList from './MintedNFTsList';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MaintenancePage from '../pages/MaintanencePage';
import { getDatabase, off, onValue, ref, update } from 'firebase/database';

const { ethers } = require("ethers");

const EarlySupporterNFT = ({props}) => {
  const [Referrals, setReferrals] = useState(0);
  const [Users, setUsers] = useState([]);
  const { connecting, ChainID, account, requestAccounts, signer, provider,wallet} = props;
  const [AccountBalance, setAccountBalance] = useState(0);
  const [Owner, setOwner] = useState("");
  const [GasPrice, setGasPrice] = useState(0);
  const [gasPriceGwei, setgasPriceGwei] = useState("");
  const [isMinting, setIsMinting] = useState(false);
  const [ButtonEnable, setButtonEnable] = useState(true);
  const [MintStatus, setMintStatus] = useState(true);
  const [UserTokenId, setUserTokenId] = useState("");
  const [Ownernonce, setOwnernonce] = useState(0);
  const [Usernonce, setUsernonce] = useState(0);

  const [TotalMintedNFT, setTotalMintedNFT] = useState(0);
  const [NFTPrice, setNFTPrice] = useState(0);
  const [BigPrice, setBigPrice] = useState(0);
  const [txhash, settxhash] = useState("");
  const [InviterAddress, setInviterAddress] = useState("");
  const [Ownertxhash, setOwnertxhash] = useState("");
  const [Status, setStatus] = useState(false);
  const [maintanence, setmaintanence] = useState(false);
  const db = getDatabase()
  const accountRef = ref(db, `accounts/${account}`)
  const mainref = ref(db, `maintanence`);

  const getReferralLink = () => {
    const referralLink = `${window.location.origin}/EarlySupporterPass?referral=${account}`;
    return referralLink
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const wallets = new ethers.Wallet(process.env.REACT_APP_PRIVATE_WALLET, provider);

  const ContractDCLESP =  new ethers.Contract(
    ESPContract,
    ESPABI,
    wallets
  );

  useEffect(() => {
      const handleValueChange = (snapshot) => {
        const data = snapshot.val();
        setmaintanence(data.enabled)
      };
      const unsubscribe = onValue(mainref, handleValueChange, {
        onlyOnce: true,
      });

      return () => {
        off(mainref);
      };
  }, [maintanence, mainref]);

  async function getCountsofTrans(){
    if(Owner){
      const nonce1 = await wallets.getTransactionCount('latest');
      setOwnernonce(nonce1)
    }
    if(account){
      const nonce2 = await provider.getTransactionCount(account, 'latest');
      setUsernonce(nonce2)
    }
  }

  const fetchData = async () => {
    try{
    if (!connecting && account) {
      logEvent(analytics, 'wallet_connection', {
        firebase_screen: 'earlysupporterpass',
        firebase_screen_class: 'wallet_connect'
      });
      const DCLESPContract = new ethers.Contract(
        ESPContract,
        ESPABI,
        signer
      );
      const balance = await provider.getBalance(account);
      setAccountBalance(ethers.utils.formatEther(balance))

      await DCLESPContract?.getUserTokens(account).then((tokens) => {
        if(tokens){
         const tokenID = tokens.toString()
         if(tokenID !== ""){
           setMintStatus(false)
           setUserTokenId(`#${tokenID}`)
           setButtonEnable(false)
         }
        }else{
         setMintStatus(true)
        }
       }).catch((err) => {
         toast.error("Somthing Went Wrong 😞", "Kindly Try again Later")
         console.log(err)
       });

      await DCLESPContract?.owner().then((owneraddress) => {
        setOwner(owneraddress)
        getCountsofTrans()
      }).catch((err) => {
        toast.error("Somthing Went Wrong 😞", "Kindly Try again Later")
        console.log(err)
      });
      await DCLESPContract?.getPrice().then((currentPrice) => {
        setNFTPrice(ethers.utils.formatEther(process.env.REACT_APP_BIGPRICE1))
        setBigPrice(ethers.utils.parseEther(process.env.REACT_APP_NFTPRICE1))
      }).catch((err) => {
        toast.error("Somthing Went Wrong 😞", "Kindly Try again Later")
        console.log(err)
      });
      
      await DCLESPContract?.getMinted().then((currentPrice) => {
        const totalMints = ethers.BigNumber.from(currentPrice).toString()
        setTotalMintedNFT(totalMints);
        if(totalMints > 100){
        setNFTPrice(ethers.utils.formatEther(process.env.REACT_APP_BIGPRICE2))
        setBigPrice(ethers.utils.parseEther(process.env.REACT_APP_NFTPRICE2))
        }

      }).catch((err) => {
        toast.error("Somthing Went Wrong 😞", "Kindly Try again Later")
        console.log(err)

      });
      
    }
    }catch(error){
      toast.error('Unexpected Error', `Error Occured on : ${error.message} Contact us if the Support is Needed in This Transaction. `);
      console.log(error)
  }
}

  useEffect(() => {
    fetchData();
  }, [signer, account, provider, UserTokenId, connecting, wallet, MintStatus, isMinting, NFTPrice, TotalMintedNFT]);

  const checkGasPrice = async () => {
    const chainIn = wallet?.chains[0]?.id;
    try {
      if(!connecting && account && chainIn === '0x89'){
        // Get the current gas price from the provider
        const gasPrice = await wallets?.provider?.getGasPrice();

        setgasPriceGwei(gasPrice)

        // Convert gas price from wei to Gwei for readability
        const gasPriceGwei2 = ethers.utils.formatUnits(gasPrice, "gwei");
        setGasPrice(gasPriceGwei2)
      }
    } catch (error) {
        console.error("Error fetching gas price:", error);
    }
};

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const inviter = params.get("referral");
    if (inviter) {
      ethers.utils.isAddress(inviter) ? setInviterAddress(inviter) : setInviterAddress('') ;
    }
    if(account && !connecting){
      onValue(ref(db,`referralMints/`), (snapshot)=>{
        const data = snapshot.val() || {}
        setReferrals(Object.values(data).filter(referers => referers.Inviter === account && referers.Inviter !== referers.Minter ).length)
      })
      // const getEstimate = async() => {
      //   const gasEstimate = (await ContractDCLESP.estimateGas.EarlySupportersMint([account],Owneroptions)).toString()
      //   console.log(ethers.utils.formatUnits(gasEstimate, 'gwei').toString())
      // }
      // getEstimate()
      getCountsofTrans()
      checkGasPrice()
    }
    return () => {
      
    };
  }, [account, connecting, isMinting, NFTPrice]);

  const maxPriorityFeePerGas = gasPriceGwei;
  const maxFeePerGas = gasPriceGwei;
  
  const Sendoptions = {
    nonce:Usernonce,
    gasLimit: ethers.utils.hexlify(
      200000),
      maxPriorityFeePerGas: maxPriorityFeePerGas,
      maxFeePerGas: maxFeePerGas,
      type: 2
  };
  const Owneroptions = {
    nonce:Ownernonce,
    gasLimit: ethers.utils.hexlify(
      200000),
    maxPriorityFeePerGas: maxPriorityFeePerGas,
    maxFeePerGas: maxFeePerGas,
    type: 2
  };
  
  // Function to call EarlySupportersMint

  async function earlySupportersMint() {
  try {
      const tx = await ContractDCLESP.EarlySupportersMint([account],Owneroptions)
      tx.wait()
      setOwnertxhash("")
      settxhash(tx?.hash)
      toast.success('Mint Successful! 🎉', 'Your Early Supporter NFT has been successfully minted!');
      console.log(tx?.hash)
      const Now = new Date()
      await update(accountRef, {
        LastHash:tx?.hash,
        date : Now,
        earlySupporterPass:'Minted',
        referedby:InviterAddress || ''
      }).then((result) => {
        console.log("updated account")
      }).catch((err) => {
        toast.error(err)
        console.log("E1",err)
      });
      await update(ref(db, `accounts/${account}/transactions/${tx?.hash}`), {
        account:account,
        LastHash:tx?.hash,
        date : Now,
        status:'Minted'
      }).then((result) => {
        console.log("updated transaction")
      }).catch((err) => {
        toast.error(err)
        console.log("E2",err)
      });
      await update(ref(db, `referralMints/${account}`), {
        Minter:account,
        Inviter:InviterAddress||'',
        MintedHash:tx?.hash,
        date : Now,
        status:'pending'
      }).then((result) => {
        console.log("updated referral")
      }).catch((err) => {
        toast.error(err)
        console.log("E3",err)
      });
      // if (ethers.utils.isAddress(InviterAddress)){
      //   const tx = {
      //     to: InviterAddress,
      //     value: ethers.utils.parseEther(NFTPrice.toString()),
      //     gasLimit: ethers.utils.hexlify(21000),
      //     maxPriorityFeePerGas: maxPriorityFeePerGas,
      //     maxFeePerGas: maxFeePerGas,
      //     type:2 
      // }
      // const trans = await wallets.sendTransaction(tx);
      // }
      setButtonEnable(false)
      setIsMinting(false)
      setMintStatus(true)
  } catch (error) {
      if (Status){
        const tx = {
          to: account,
          value: ethers.utils.parseEther(NFTPrice.toString()),
          gasLimit: ethers.utils.hexlify(21000),
          maxPriorityFeePerGas: maxPriorityFeePerGas,
          maxFeePerGas: maxFeePerGas,
          type:2 
      };
      const trans = await wallets.sendTransaction(tx);
      toast.success("Refund Initiated...")
      }
      toast.error('Unexpected Error', `Error Occured on : ${error.message} Contact us if the Support is Needed in This Transaction. `);
      console.log("Minting Error",error)
      setIsMinting(true)
      setButtonEnable(true)
      setMintStatus(false)
  }
}

  const txview = `https://polygonscan.com/tx/${txhash}`
  const Ownertxview = `https://polygonscan.com/tx/${Ownertxhash}`

  const buttonClass = MintStatus 
    ? (isMinting ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700') 
    : 'bg-gray-500 cursor-not-allowed';

  const ButtonStatus = MintStatus ? (isMinting ? 'Please wait Minting...' : 'Mint Now') : `Already Minted ${UserTokenId}`

  useEffect(() => {
    if (wallet?.provider) {
      wallet.provider.on('chainChanged', (chainId) => {
        console.log('chainChanged', chainId);
        fetchData()
      });

      wallet?.provider.on('accountsChanged', (accounts) => {
        console.log('accountsChanged', accounts);
        fetchData()
      });
      
    }
  }, [wallet]);

  function shortenAddress(chars = 6) {
    if (!account) return "";
    return account.slice(0, chars) + "..." + account.slice(-chars);
  }

  

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'earlysupporterpass',
      firebase_screen_class: 'NFT Mint'
    });
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  function calculateTimeLeft() {
    const targetDate = new Date('2024-06-20T06:30:00+05:30'); // 20-06-2024 12:00 PM IST
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }
  
      return timeLeft;
    }

    const mintNFT = async () => {
      if(AccountBalance < NFTPrice){
        toast.error("Insufficiant Balance 😞")
        return ""
      }
      setOwnertxhash("Transaction Confirming")
      setButtonEnable(false)
      setIsMinting(true)
      if(UserTokenId){
        setMintStatus(false)
        return ""
      }
      logEvent(analytics, 'Pass_Mint_Action', {
          firebase_screen: 'earlysupporterpass',
          firebase_screen_class: 'Mint_Click'
        });
        if (!connecting && account) {
          if(Owner){
        try {
          const tx = {
            to: Owner,
            value: ethers.utils.parseEther(NFTPrice.toString()),
            gasLimit: ethers.utils.hexlify(21000),
            maxPriorityFeePerGas: maxPriorityFeePerGas,
            maxFeePerGas: maxFeePerGas,
            type:2 
        };
          const trans = await signer.sendTransaction(tx);
          trans.wait()
          setStatus(true)
          const Now = new Date()
          if(trans && typeof trans === 'string'){
            setOwnertxhash(trans?.hash)
          }
          await update(accountRef, {
            account:account,
            LastHash:trans?.hash,
            date :Now,
          })
          await update(ref(db, `accounts/${account}/transactions/${trans?.hash}`), {
            account:account,
            LastHash:trans?.hash,
            date : Now,
            status:'completed'
          })
          earlySupportersMint()
        } catch (error) {
          if(error.code === "ACTION_REJECTED" || error.code === 5001 || error.message === '' ){
            toast.error('Transaction rejected  😞', 'User has cancelled the transaction');
            setIsMinting(false)
            setButtonEnable(true)
            setOwnertxhash("")
          }else{
            toast.error('Unexpected Error', `Error Occured on : ${error} Contact us if the Support is Needed in This Transaction. `);
            console.log("Sending Error",error)
            setIsMinting(false)
            setButtonEnable(true)
            setOwnertxhash("")

          }
        }
          }else{
        toast.error('Network Error 😞', 'Please try Again After Some Time ');
        setIsMinting(false)
        setButtonEnable(true)
        setOwnertxhash("")
        }
        }else{
        toast.error('Wallet Disconnected 😞', 'Connect your Wallet and Try Again');
        setIsMinting(false)
        setButtonEnable(true)
        setOwnertxhash("")
        }
      };

      const timerComponents = Object.keys(timeLeft).map((interval) => {
        if (!timeLeft[interval]) {
          return null;
        }
        return (
            <span key={interval} className="px-2">
              {timeLeft[interval]} {interval}{' '}
            </span>
          );
        });

        const copyToClipboard = () => {
          navigator?.clipboard
            ?.writeText(getReferralLink().toString())
            .then(() => {
              toast.success("Referrall Link Copied")
            })
            .catch((error) => {
              console.error("Error copying text to clipboard:", error);
              toast.error(error?.message)
              // Handle the error, such as showing an error message to the user
            });
        };

  return (<>

    {maintanence ? <MaintenancePage/> : <div className="min-h-screen bg-gray-900 text-white py-8">
      {/* Header */}
      <header className="container mx-auto text-center mb-12">
        <h1 className="text-4xl font-bold mb-4">🎉 Early Supporter NFT 🎉</h1>
        <p className="text-xl">Join our exclusive club by minting your Early Supporter NFT today!</p>
      </header>

      {/* NFT Display */}
      <section className="container mx-auto text-center mb-12">
        <img src="/earlySupporterBadge.png" alt="Early Supporter NFT" className="mx-auto mb-4 rounded-lg shadow-lg h-96 md:h-15" />
        <p className="text-lg">Our unique NFT designed exclusively for our early supporters.</p>
        <p className="text-lg mt-4">
          <strong>Price:</strong> First 100 NFTs at 10 MATIC each, then 25 MATIC each from the 100th NFT onwards.
        </p>
        <p className="text-lg mt-4">
          <strong>Total Supply:</strong> 10,000 NFT's
        </p>
        <p className="text-lg mt-4">
          <strong>Mint Date :</strong> 20 June 2024 12:00 PM IST
        </p>
      </section>
      {/* Minting Section */}
      <section className="container mx-auto ,d:mx-8 text-center mb-12 bg-gray-800 p-8 rounded-lg shadow-lg">
        {/* <h2 className="text-3xl font-bold mb-4">Mint Your NFT</h2>
        <p className="mb-4">Become an early supporter and get your exclusive NFT!</p>
        <p className="mb-6">
          Be one of the first to mint our exclusive Early Supporter NFT! Limited availability, act fast!
        </p>
        <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg">Mint Now</button>
         */}
        {Object.keys(timeLeft).length > 0 ? (
          <div>
            <h2 className="text-3xl font-bold mb-4">Minting Starts In:</h2>
            <p className="mb-4">
        🎉 Be one of the first to mint our exclusive Early Supporter NFT! Limited availability, act fast!
      </p>
            <div className="text-xl font-mono">
              {timerComponents.length ? timerComponents : <span>Minting Started!</span>}
            </div>
          </div>
        ) : (
          <div>
            {account ? (
              <div>
              <div className='flex justify-end mb-4'>
                 <p className="py-2 px-4 font-bold text-white rounded-lg bg-slate-600">
          <strong> Balance({shortenAddress(5)}): </strong> {AccountBalance}{" Matics"}
        </p>
        </div>
                <h2 className="text-3xl font-bold mb-4">Minting Started :</h2>
                <p className="mb-4">
        🎉 Be one of the first to mint our exclusive Early Supporter NFT! Limited availability, act fast!
      </p>
                <button
                  onClick={() => mintNFT()}
                  className={`py-2 px-4 font-bold text-white rounded-lg ${buttonClass}`} 
                  disabled={!ButtonEnable}
                >
                  {ButtonStatus}
                </button>
        {InviterAddress === account ? <p className='text-white mt-4'>Referrer Account and User Account Cant be the Same </p>:"" }

                <p className="text-lg my-4">
          <strong>NFT Price :</strong> {NFTPrice} {" Matics "}
        </p>
                <p className="text-lg my-4">
          <strong>Total Minted NFT's :</strong> {TotalMintedNFT }{" "}of{" 10,000"}
        </p>
        {Ownertxhash ?
        <p className="text-lg my-4">
        <strong>Kindly Dont Leave this Screen Transaction is Under Proccess...</strong>
        </p> : ""
        }
        {txhash?
        <p className="text-lg my-4">
        <strong>Mint Successful! 🎉 :</strong> <a href={txview} target='blank' className="text-blue-500 hover:underline">View Transaction</a>
        </p> : ""
        }

              </div>
            ) : (<div>
              <h2 className="text-3xl font-bold mb-4">Minting Started :</h2>
      <p className="mb-6">
        🎉 Be one of the first to mint our exclusive Early Supporter NFT! Limited availability, act fast!
      </p>
              <button
                onClick={requestAccounts}
                className="py-2 px-4 font-bold text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                >
                Connect Wallet
              </button>
                </div>
            )}
          </div>
        )}
      {UserTokenId && account ?<>
  <h2 className="text-3xl font-bold text-white">Minted Passes</h2>
        <MintedNFTsList props={props}/>
      </>:""}
      </section>

      {account ? 
      <section className="container mx-auto text-center mb-12 bg-gray-800 p-8 rounded-lg shadow-lg">
      <h2 className="text-2xl text-white font-bold mb-6">Your Referral Link:</h2>
      
      <div className="flex items-center mb-6">
        <input
          type="text"
          value={getReferralLink()}
          readOnly
          className="flex-1 bg-gray-100 border text-gray-800 border-gray-300 rounded-lg px-4 py-2 mr-3 focus:outline-none focus:ring-2 focus:ring-green-500"
        />
        <button
          className="bg-green-500 hover:bg-green-600 text-white py-2 px-6 rounded-lg font-semibold transition-transform transform hover:scale-105"
          onClick={copyToClipboard}
        >
          Copy Link
        </button>
      </div>
      <p>Total Referrals : {Referrals}</p>
    </section>
    
      :<p></p>}

      {account ? <div className="container mx-auto text-center mb-12 bg-gray-800 p-8 rounded-lg shadow-lg">
        <p className="text-lg text-green-400 font-bold mb-3">📈 Referral Benefits</p>
        
        <p className="text-white mb-4">
          When someone mints an NFT using your referral link, <span className="font-semibold text-yellow-300">3% of the minting fee</span> will be transferred to your wallet! 💸
        </p>
        
        <p className="text-white mb-4">
          <span className="font-semibold text-green-300">Referral Fee:</span> 3% of NFT Minting Fee 🪙
        </p>
        
        <p className="text-white mb-4">
          <span className="font-semibold text-blue-300">Usage of Balance:</span> All NFT Mints Amount will be used to <span className="font-semibold text-purple-300">add liquidity</span> to the DCL Crown V2 Tokens. 📈🔄
        </p>
        
        <p className="text-white">
          Help us grow the ecosystem and earn rewards for your referrals! 🌟
        </p>
      </div> :''}

      {/* Pass Section */}
<section className="bg-gray-800 py-12 mb-8">
  <div className="container mx-auto px-6 lg:px-12">
    <h2 className="text-4xl font-bold text-center text-white mb-8">Discover the Exciting World of Early Supporter Passes!</h2>
    <p className="text-xl text-center text-yellow-400 mb-12">Get ready to embark on an extraordinary journey where rarity meets rewards!</p>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
      <div className="text-center">
        <img src="/earlySupporterBadge.png" alt="Gold Pass" className="mx-auto mb-6 h-48" />
        <h3 className="text-2xl font-semibold text-white mb-4">Gold Pass #1</h3>
        <p className="text-lg text-gray-300 mb-4">The pinnacle of exclusivity!</p>
        <p className="text-lg text-yellow-400">Only 20% of passes are Gold, offering unparalleled benefits and prestige.</p>
      </div>
      <div className="text-center">
        <img src="/earlysupporterdiamondpass.png" alt="Diamond Pass" className="mx-auto mb-6 h-48" />
        <h3 className="text-2xl font-semibold text-white mb-4">Diamond Pass #2</h3>
        <p className="text-lg text-gray-300 mb-4">Shine bright like a diamond!</p>
        <p className="text-lg text-blue-400">With a rarity of 30%, Diamond Pass holders enjoy premium privileges and unique rewards.</p>
      </div>
      <div className="text-center">
        <img src="/earlysupportersilverpass.png" alt="Silver Pass" className="mx-auto mb-6 h-48" />
        <h3 className="text-2xl font-semibold text-white mb-4">Silver Pass #3</h3>
        <p className="text-lg text-gray-300 mb-4">Join the majority with a 50% chance of securing a Silver Pass!</p>
        <p className="text-lg text-gray-400">While common, Silver Passes open the door to a world of opportunities and camaraderie.</p>
      </div>
    </div>
  </div>
</section>



      {/* Benefits Section */}
      <section className="container mx-auto text-center mb-12">
        <h2 className="text-3xl font-bold mb-4">Benefits of Early Supporter NFT</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold mb-2">🌟 Exclusive Access</h3>
            <p>Get early access to new features and updates.</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold mb-2">🎁 Special Rewards</h3>
            <p>Receive special rewards and giveaways.</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold mb-2">🛡️ VIP Support</h3>
            <p>Enjoy priority support from our team.</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-bold mb-2">🌐 Community Recognition</h3>
            <p>Stand out as an early supporter in our community.</p>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="container mx-auto text-center">
        <p>Follow us on social media:</p>
        <div className="flex justify-center space-x-4 mt-4">
          <a href="https://twitter.com/dailycashloot" target='blank' className="text-blue-500 hover:underline">Twitter</a>
          <a href="https://t.me/dailycashlootofficial" target='blank' className="text-blue-700 hover:underline">Telegram</a>
          <a href="https://instagram.com/dailycashlootofficial" target='blank' className="text-pink-500 hover:underline">Instagram</a>
        </div>
      </footer>
    </div>}
  </>
  );
};

export default EarlySupporterNFT;
