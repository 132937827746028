// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, onValue, ref, set, update } from "firebase/database"; // For Realtime Database
import { getAuth, GoogleAuthProvider, TwitterAuthProvider } from "firebase/auth"; // For Realtime Database
import { getFirestore } from "firebase/firestore"; // For Firestore
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const storage = getStorage(app, `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`)

const analytics = getAnalytics(app);

const googleProvider = new GoogleAuthProvider();

const twitterProvider = new TwitterAuthProvider();

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

// Initialize Firestore and get a reference to the service
const firestore = getFirestore(app);

const db = getDatabase()


const {currentUser} = auth

const createUser = (userdata) => {
  if(auth?.currentUser?.uid){
    const userref = ref(db, `users/${currentUser?.uid}`)
    set(userref, userdata).then((result) => {
      return result
    }).catch((err) => {
      return err
    });
  }
}
const updateUser = (userID,userdata) => {
  if(userID){
    const userref = ref(db, `users/${userID}`)
    update(userref, userdata).then((result) => {
      return result
    }).catch((err) => {
      return err
    });
  }
}
export { database, firestore, analytics, storage, auth,googleProvider, twitterProvider, createUser, updateUser  };
