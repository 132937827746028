import React from "react";

const Whitepaper = () => {
  return (
    <div className="bg-white min-w-full min-h-screen container mx-auto px-4 py-4">
      <div className="container mx-auto px-4">
        <h1 className="text-3xl font-bold mb-4">Whitepaper</h1>
        <div className="bg-gradient-to-br from-green-600 to-green-300 shadow-lg rounded-lg p-6 mb-4">
          <h2 className="text-xl text-white font-bold mb-4">
            {" "}
            Our Vision and Goals
          </h2>
          <h2 className="text-xl text-white font-bold mb-2 ml-4">Vision</h2>
          <p className="text-white ml-4">
            Our vision with DCL CROWN Token is to create a thriving
            decentralized virtual world community where members can collaborate,
            participate in Airdrops, and benefit from exclusive rewards and
            incentives.
          </p>
          <h2 className="text-xl font-bold mb-2 text-white  mt-4 ml-4">
            Goals
          </h2>
          <ul className="list-disc pl-4 text-white ml-4">
            <li>
              Create a vibrant and engaged community around DCL CROWN Token.
            </li>
            <li>
              Empower community members with DCL CROWN for the Active
              Partispation and Completing Task
            </li>
            <li>
              Offer exclusive benefits and rewards to token holders, such as
              access to events, airdrops, and discounts.
            </li>
            <li>
              Drive adoption and utility of DCL CROWN Token within the
              decentralized virtual world ecosystem.
            </li>
            <li>
              Establish strategic partnerships and collaborations to enhance the
              token's value and ecosystem growth.
            </li>
          </ul>
        </div>
      </div>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4">Timeline</h1>
        <div className="bg-gradient-to-br from-rose-600 to-rose-300 shadow-lg rounded-lg p-6 mb-4">
          <h2 className="text-xl text-white font-bold mb-2">Key Milestones</h2>
          <div className="flex flex-col space-y-4">
            <div className="flex items-center">
              <div className="h-8 w-8 flex-none rounded-full bg-indigo-500 flex items-center justify-center">
                <span className="text-white font-bold">1</span>
              </div>
              <div className="ml-4">
                <h3 className="text-lg text-white font-bold">
                  Project Initiation
                </h3>
                <p className="text-white">Launch of DCL CROWN Token project.</p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="h-8 w-8 flex-none rounded-full bg-indigo-500 flex items-center justify-center">
                <span className="text-white font-bold">2</span>
              </div>
              <div className="ml-4">
                <h3 className="text-lg text-white  font-bold">
                  Token Offering
                </h3>
                <p className="text-white">
                  Initial token offering to the community.
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="h-8 w-8 flex-none rounded-full bg-indigo-500 flex items-center justify-center">
                <span className="text-white font-bold">3</span>
              </div>
              <div className="ml-4">
                <h3 className="text-lg text-white font-bold">
                  Providing Liquidity
                </h3>
                <p className="text-white">
                  Adding Liquidity to DCL Crown Token in a Dex
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="h-8 w-8 flex-none rounded-full bg-indigo-500 flex items-center justify-center">
                <span className="text-white font-bold">4</span>
              </div>
              <div className="ml-4">
                <h3 className="text-lg text-white font-bold">
                  Promoting Trade
                </h3>
                <p className="text-white">
                  Providing Incentives for Traders on DEX.
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="h-8 w-8 flex-none rounded-full bg-indigo-500 flex items-center justify-center">
                <span className="text-white font-bold">5</span>
              </div>
              <div className="ml-4">
                <h3 className="text-lg text-white font-bold">
                  Implementing Quests
                </h3>
                <p className="text-white">
                  Rewarding users for Completing Quests.
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="h-8 w-8 flex-none rounded-full bg-indigo-500 flex items-center justify-center">
                <span className="text-white font-bold">6</span>
              </div>
              <div className="ml-4">
                <h3 className="text-lg text-white font-bold">Listing on CEX</h3>
                <p className="text-white">Listing DCL CROWN in Major CEX</p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="h-8 w-8 flex-none rounded-full bg-indigo-500 flex items-center justify-center">
                <span className=" text-white font-bold">7</span>
              </div>
              <div className="ml-4">
                <h3 className="text-lg text-white font-bold">
                  Lauching Minigames
                </h3>
                <p className="text-white">
                  Lauching Minigames and Rewarding users with Rewards
                </p>
              </div>
            </div>
            {/* Add more milestones as needed */}
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 py-4 mb-4">
        <div className="bg-gradient-to-br from-blue-600 to-blue-300 shadow-lg rounded-lg p-6">
          <h2 className="text-xl text-white font-bold mb-2">
            Features and Development Phases
          </h2>
          <p className="text-white">
            Detailed features and functionalities of DCL CROWN Token will be
            Updated Soon.
          </p>
        </div>
      </div>
      {/* Include other sections as needed */}
    </div>
  );
};

export default Whitepaper;
