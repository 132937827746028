import React, {useEffect, useState} from "react";
import TwitterFollowButton from "./TwitterFollowButton";
import TelegramButton from "./TelegramButton";
import DiscordButton from "./DiscordButton";
import KeyFeatures from "./KeyFeatures";
import { useNavigate } from "react-router-dom";

import { analytics } from './firebaseConfig';
import { logEvent } from 'firebase/analytics';
import { TokensAddress } from "./utils/constants";
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from "react-helmet-async";
import Tokenomics from "../pages/Tokenomics";
import { FaUsers, FaKey, FaCheckCircle, FaRocket, FaCube, FaDollarSign, FaLightbulb, FaDiscord, FaTelegramPlane, FaTwitter } from "react-icons/fa";
import AdFrame from "../Ads/AdFrame";

const Dashboard = ({props}) => {

  
  const {provider, connecting} = props

  const navigate = useNavigate()

  const tokenAddress = TokensAddress;
  const tokenSymbol = "CROWN";
  const tokenDecimals = 18;
  const tokenImage = "https://firebasestorage.googleapis.com/v0/b/dailycashloot.appspot.com/o/20240415_093647.png?alt=media&token=3825e640-02aa-4387-ade7-86be7ee98494";

  const [expanded, setExpanded] = useState(null);

  const toggleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const addToken = async() => {
    
try {
  // 'wasAdded' is a boolean. Like any RPC method, an error can be thrown.
  if(!connecting && window.ethereum){
    const wasAdded = await window.ethereum // Or window.ethereum if you don't support EIP-6963.
    .request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          // The address of the token.
          address: tokenAddress,
          // A ticker symbol or shorthand, up to 5 characters.
          symbol: tokenSymbol,
          // The number of decimals in the token.
          decimals: tokenDecimals,
          // A string URL of the token logo.
          image: tokenImage,
        },
      },
    });
    if (wasAdded) {
      toast("Thanks for Adding Our Token 😊")
      // showDialog("Thanks for Adding Our Token 😊", "You are the real supporter of our Community. ")
    } else {
      // showDialog("Token is Not Added 😞", "If you face any problem adding our Token Contact us on Dailycashloot@gmail.com. Feel Free to Contact us 😊 ")
      toast("Token is Not Added 😞. If you face any problem adding our Token Contact us on Dailycashloot@gmail.com. Feel Free to Contact us 😊")
    }

  }else{
    toast("Wallet Connection is Required to Add Our Token ")
    // showDialog("Wallet Not Connected", "Connect your Wallet to Add Our Token ")
  }
  
} catch (error) {
  toast.error("Something went wrong 😞")

}
  }
  const {
    showDialog
  } = props;

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'dashboard',
      firebase_screen_class: 'Dashboard'
    });
  }, []);

  const handlestart = () => {
    navigate('/EarlySupporterPass')
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {/* <MiningPage/> */}
      <Helmet>
        <title>Discover Crypto Opportunities with Dailycashloot | Earn Rewards & Access Airdrops | Explore DApps & NFTs</title>
        <meta name="description" content="Join Dailycashloot for exclusive crypto opportunities! Earn rewards, access the latest airdrops, explore innovative DApps, and become part of our thriving crypto community." />
        <meta name="keywords" content="openseas,Web3 tools,NFT minting, Task-based earning,LaunchPad for crypto projects,Supportive crypto community, crypto, daily, cash, loot, airdrop, dailycashloot, task, notcoin, hamster, hamsterkombat, hotwallet, dormint, catizen, latest"/>
        {/* Add more meta tags as needed */}
      </Helmet>
       <div className="bg-gradient-to-br from-gray-800 to-black rounded-b-3xl">
      <div className="container mx-auto py-8 px-4 md:px-8">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="flex flex-col md:flex-row justify-center text-center items-center">
            <img
              src="/logo.png"
              alt="DCL Logo"
              className="h-12 w-12 md:h-16 md:w-16 mr-4"
              />
            <h1 className="text-white text-xl md:text-3xl font-bold">
            Dailycashloot - Task & Crypto
            </h1>
          </div>
          {/* <div className="flex items-center space-x-4 mt-4 md:mt-0">
            <TwitterFollowButton username="dailycashloot" />
            <TelegramButton username="dailycashlootofficial" />
            <DiscordButton />
            </div> */}
        </div>
        <div className="mt-6 text-center md:text-left">
          <h2 className="text-white text-lg md:text-xl font-bold">
            Your Gateway to Crypto Opportunities
          </h2>
          <p className="text-white mt-2 md:mt-4 text-base md:text-lg">
            Discover new airdrops, earn rewards, explore DApps, and more!
          </p>
          <div className="flex justify-center md:justify-start">
          <button onClick={()=>navigate('tasks')} className="mt-4 bg-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg hover:text-gray-100 transition-colors duration-300 hover:shadow-2xl">
            Complete Tasks
          </button>
          <button onClick={handlestart} className="ml-4 mt-4 bg-blue-600 text-white font-bold py-2 px-6 rounded-full shadow-lg hover:bg-blue-500 transition-all duration-300 hover:shadow-2xl focus:outline-none focus:ring-2 focus:ring-blue-400">
            Mint Pass
          </button>
          </div>
        </div>
      </div>
    </div>
            <AdFrame/>

      <div className="bg-white">
        <KeyFeatures />
      </div>
  <div className="bg-gray-100 py-12">
        <div className="container mx-auto px-4 md:px-8">
          <div className="max-w-4xl mx-auto text-center">
            <div className="flex justify-center mt-6">
              <FaUsers className="h-16 w-16 text-orange-600 animate-bounce" />
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-orange-600 mb-4 animate-fade-in">
              About Us
            </h2>
            <p className="text-lg text-gray-800 leading-relaxed animate-slide-in-up">
              Discover Dailycashloot, your gateway to the latest crypto opportunities. We empower users with airdrops, reward-earning tasks, and a supportive community for crypto enthusiasts.
            </p>
          </div>
        </div>
      </div>


      <div className="bg-gradient-to-r from-blue-200 via-blue-100 to-white py-12">
      <div className="container mx-auto px-4 md:px-8">
        <div className="text-center">
          <h2 className="font-bold text-3xl md:text-4xl mb-6 underline text-blue-700 animate-fade-in">
            How It Works
          </h2>
          <p className="text-lg text-gray-800 mb-8 animate-slide-in-up">
            Getting started with Dailycashloot is easy:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="flex items-center justify-center bg-white p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
              <FaKey className="text-blue-600 text-4xl mb-4 mr-4" />
              <div className="text-left">
                <h3 className="font-semibold text-xl text-gray-800 mb-2">Create an Account</h3>
                <p className="text-gray-600">Connect your wallet and join our community.</p>
              </div>
            </div>
            <div className="flex items-center justify-center bg-white p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
              <FaCheckCircle className="text-blue-600 text-4xl mb-4 mr-4" />
              <div className="text-left">
                <h3 className="font-semibold text-xl text-gray-800 mb-2">Explore Opportunities</h3>
                <p className="text-gray-600">Browse available airdrops and tasks.</p>
              </div>
            </div>
            <div className="flex items-center justify-center bg-white p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
              <FaDollarSign className="text-blue-600 text-4xl mb-4 mr-4" />
              <div className="text-left">
                <h3 className="font-semibold text-xl text-gray-800 mb-2">Earn Tokens</h3>
                <p className="text-gray-600">Complete tasks to earn DCL CROWN tokens.</p>
              </div>
            </div>
            <div className="flex items-center justify-center bg-white p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
              <FaRocket className="text-blue-600 text-4xl mb-4 mr-4" />
              <div className="text-left">
                <h3 className="font-semibold text-xl text-gray-800 mb-2">Support Projects</h3>
                <p className="text-gray-600">Discover and support innovative crypto projects.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-white py-12">
      <div className="container mx-auto px-4 md:px-8">
        <div className="text-center">
          <h2 className="font-bold text-3xl md:text-4xl mb-6 underline text-purple-700 animate-fade-in">
            Features
          </h2>
          <p className="text-lg text-gray-800 mb-8 animate-slide-in-up">
            Our platform offers a range of features to enhance your crypto experience:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="flex items-center justify-start bg-white p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
              <FaCube className="text-purple-600 text-4xl mb-4 mr-4" />
              <div className="text-left">
                <h3 className="font-semibold text-xl text-gray-800 mb-2">Latest Airdrops</h3>
                <p className="text-gray-600">Access to the latest airdrops and earn opportunities.</p>
              </div>
            </div>
            <div className="flex items-center justify-start bg-white p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
              <FaRocket className="text-purple-600 text-4xl mb-4 mr-4" />
              <div className="text-left">
                <h3 className="font-semibold text-xl text-gray-800 mb-2">LaunchPad</h3>
                <p className="text-gray-600">LaunchPad for new projects and innovations.</p>
              </div>
            </div>
            <div className="flex items-center justify-start bg-white p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
              <FaCube className="text-purple-600 text-4xl mb-4 mr-4" />
              <div className="text-left">
                <h3 className="font-semibold text-xl text-gray-800 mb-2">NFT Minting</h3>
                <p className="text-gray-600">NFT minting and trading.</p>
              </div>
            </div>
            <div className="flex items-center justify-start bg-white p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
              <FaCheckCircle className="text-purple-600 text-4xl mb-4 mr-4" />
              <div className="text-left">
                <h3 className="font-semibold text-xl text-gray-800 mb-2">Trending DApps</h3>
                <p className="text-gray-600">Access to trending DApps and Web3 tools.</p>
              </div>
            </div>
            <div className="flex items-center justify-start bg-white p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
              <FaLightbulb className="text-purple-600 text-4xl mb-4 mr-4" />
              <div className="text-left">
                <h3 className="font-semibold text-xl text-gray-800 mb-2">Community Support</h3>
                <p className="text-gray-600">Community-driven project submissions and support.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div className="bg-gray-100 py-12">
        <div className="container mx-auto px-4 md:px-8">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl md:text-4xl font-bold text-red-600 mb-4">
              Frequently Asked Questions (FAQ)
            </h2>
            <div className="text-left">
              {[
                { question: "What is Dailycashloot?", answer: "Dailycashloot is a community platform where users can access the latest crypto airdrops, task & earn opportunities, trending DApps, donate to startups, submit projects, and raise funds for their projects. Our goal is to promote various projects and provide users access to these opportunities." },
                { question: "How can I join Dailycashloot?", answer: "You can join Dailycashloot by signing up on our website. Simply click the 'Sign Up' button, fill in the required details, and you're all set!" },
                { question: "Is it Dailycashloot is Secured ?", answer: "Yes, Dailycashloot is secure. We prioritize user security through wallet-based authentication, secure data storage with Firebase Realtime Database, and blockchain integration for transparent and immutable transactions. Regular security audits and strict data privacy policies ensure your information is protected. Our task verification and fraud prevention measures maintain the integrity of the platform, making Dailycashloot a safe place to complete tasks and earn rewards." },
                { question: "Is there a fee to join Dailycashloot?", answer: "No, joining Dailycashloot is free. However, there are fees for certain services like project publishing, which are collected as our native Crown Token." },
                { question: "What is the Crown Token?", answer: "The Crown Token is our native cryptocurrency used within the Dailycashloot platform. It can be earned through tasks, airdrops, and other activities." },
                { question: "How do I earn Crown Tokens?", answer: "You can earn Crown Tokens by participating in tasks, airdrops, and various activities on the Dailycashloot platform. Keep an eye on our website and social media channels for the latest opportunities." },
                { question: "I am having trouble connecting my wallet. What should I do?", answer: "If you're having trouble connecting your wallet, ensure that you are using a supported browser and have the necessary extensions installed. You can also refer to our help section or contact our support team for assistance." },
                { question: "I found a bug. How can I report it?", answer: "We appreciate your help in making Dailycashloot better. Please report any bugs or issues through our contact form or by emailing us at dailycashloot@gmail.com." },
              ].map((faq, index) => (
                <div key={index} className="mb-4">
                  <button
                    className="w-full text-left text-lg font-semibold text-gray-800 bg-gray-200 py-2 px-4 rounded-md focus:outline-none focus:bg-gray-300 transition-colors"
                    onClick={() => toggleExpand(index)}
                  >
                    {faq.question}
                  </button>
                  {expanded === index && (
                    <div className="mt-2 mx-4 text-gray-700">
                      {faq.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
  {/* <div className="flex justify-center items-center bg-gray-200 p-8">
    <div>
      <h2 className="font-bold text-2xl mb-4 underline text-center text-red-600">
        Get Started Today!
      </h2>
      <p className="text-center text-gray-700 mb-4">
        Sign up now and start earning DCL CROWN tokens. Discover new opportunities and be a part of the future of crypto with Dailycashloot.
      </p>
      <div className="flex justify-center">
        <button className="mt-4 bg-blue-600 text-white font-bold py-2 px-4 rounded-full shadow-lg hover:bg-blue-700 transition-colors duration-300">
          Get Started
        </button>
      </div>
    </div>
  </div> */}
  {/* <div className="flex justify-center items-center bg-white p-8">
    <div>
      <h1 className="font-bold text-2xl mb-4 underline text-center text-rose-600">
        Our Dex Partner
      </h1>
      <div className="flex justify-center items-center mt-4">
        <img
          src="/uniswaplogopng.png"
          alt="Uniswap"
          className="h-10 w-auto"
        />
      </div>
    </div>
  </div> */}
      <AdFrame/>

    </div>
  );
};

export default Dashboard;
