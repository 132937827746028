import React from 'react';

const MaintenancePage = () => {
  return (
    <div className="min-h-screen bg-gray-900 text-white py-8 px-4 flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">🚧 Under Maintenance 🚧</h1>
        <p className="text-xl mb-4">
          Our NFT minting service is currently undergoing maintenance. We are working hard to fix the issues and will be back soon.
        </p>
        <p className="text-lg">
          Thank you for your patience and understanding. Please check back later.
        </p>
      </div>
    </div>
  );
};

export default MaintenancePage;
