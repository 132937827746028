import React, { useEffect, useState } from 'react';
import { getDatabase, ref, set, update, remove, onValue, off, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Avatar, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const AdminTaskPage = () => {
  const [tasks, setTasks] = useState([]);
  const [userTasks, setUserTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [currentTask, setCurrentTask] = useState(null);
  const [reviewTask, setReviewTask] = useState({ taskId: '', status: '', userId: '', points: 0, description: '' });
  const [newTask, setNewTask] = useState({ title: '', description: '', taskUrl: '', points: '', type: '' });
  const { currentUser } = getAuth();
  const db = getDatabase();
  const tasksRef = ref(db, 'tasks/');
  const taskHistoryRef = ref(db, 'taskshistory');
  const userRef = ref(db, 'users/');
  const pointsHistoryRef = ref(db, 'pointshistory');

  const Admin = process.env.REACT_APP_EMAIL;

  // Fetch tasks
  useEffect(() => {
    const handleTasksChange = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setTasks(Object.entries(data).map(([id, task]) => ({ id, ...task })));
      }
    };

    onValue(tasksRef, handleTasksChange);

    return () => {
      off(tasksRef);
    };
  }, []);

  // Fetch users and their task histories
  useEffect(() => {
    const handleUsersChange = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const dataList = Object.entries(data).map(([id, user]) => ({ id, ...user }));
        setUsers(dataList);
        fetchAllTaskHistories(dataList);
      }
    };

    onValue(userRef, handleUsersChange);

    return () => {
      off(userRef);
    };
  }, []);

  const fetchAllTaskHistories = (usersList) => {
    usersList.forEach(user => {
      const userTaskHistoryRef = ref(db, `taskshistory/${user.id}`);
      onValue(userTaskHistoryRef, (snapshot) => {
        const historyData = snapshot.val();
        if (historyData) {
          setUserTasks(prev => ({ ...prev, [user.id]: historyData }));
        }
      });
    });
  };

  const handleOpenDialog = (type, task = null) => {
    setDialogType(type);
    setCurrentTask(task);
    if (type === 'edit' && task) {
      setNewTask({ title: task.title, description: task.description, taskUrl: task.taskUrl, points: task.points, type:task.type });
    } else if (type === 'review' && task) {
      setCurrentTask(task.id)
      setReviewTask({ taskId: task.id, status: task.status || '', userId: task.userId, points: task.points, description: task.description, type:task.type });
    } else {
      setNewTask({ title: '', description: '', taskUrl: '', points: '', type:'' });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewTask({ title: '', description: '', taskUrl: '', points: '',  type:''  });
    setCurrentTask(null);
    setReviewTask({ taskId: '', status: '', userId: '', points: 0, description: '',  type:''  });
  };

  const handleAddTask = () => {
    const taskId = new Date().toISOString().replace(/[:.]/g, '-');
    set(ref(db, `tasks/${taskId}`), {
      title: newTask.title,
      description: newTask.description,
      taskUrl: newTask.taskUrl,
      points: Number(newTask.points),
    });
    handleCloseDialog();
  };

  const handleEditTask = () => {
    if (currentTask) {
      update(ref(db, `tasks/${currentTask.id}`), {
        title: newTask.title,
        description: newTask.description,
        taskUrl: newTask.taskUrl,
        points: Number(newTask.points),
      });
      handleCloseDialog();
    }
  };

  const handleDeleteTask = (taskId) => {
    remove(ref(db, `tasks/${taskId}`));
  };

  const handleUpdateTaskStatus = () => {
    if (reviewTask.taskId) {
      setCurrentTask(reviewTask.taskId)
      const now2 = new Date().toISOString();
      update(ref(db, `taskshistory/${reviewTask.userId}/${reviewTask.taskId}`), { status: reviewTask.status })
        .then(() => {
          if (reviewTask.status === 'approved') {
            get(ref(db, `pointshistory/${reviewTask.userId}`)).then(snapshot => {
              const currentHistory = snapshot.val() || [];
              const newHistory = [
                ...currentHistory,
                { date: now2, points: reviewTask.points, description: `${reviewTask.description} Task Completed` },
              ];

              update(ref(db, `pointshistory/${reviewTask.userId}`), {
                ...newHistory,
              });
              update(ref(db, `users/${reviewTask.userId}`), {
                points: (users.find(user => user.id === reviewTask.userId)?.points || 0) + reviewTask.points,
              });
            }).catch(err => {
              console.log('Error updating points history:', err);
            });
          }else{
            update(ref(db, `taskshistory/${reviewTask.userId}/${reviewTask.taskId}`), { status: reviewTask.status })
          }
        }).catch(err => {
          console.error('Error updating task status:', err);
        });

      handleCloseDialog();
    }
  };

  if (currentUser.email !== Admin) {
    return (
      <div className="flex bg-gray-900 min-h-screen p-8 justify-center items-center text-white">
        User is Not Authorized to Access this Page
      </div>
    );
  }

  const pendingTasks = Object.entries(userTasks).flatMap(([userId, tasks]) =>
    Object.entries(tasks).filter(([_, task]) => task.status === 'reviewing'|| task.status === 'pending').map(([taskId, task]) => ({ ...task, userId }))
  );
  const clickedTasks = Object.entries(userTasks).flatMap(([userId, tasks]) =>
    Object.entries(tasks).filter(([_, task]) => task.status === 'clicked').map(([taskId, task]) => ({ ...task, userId }))
  );
  const completedTasks = Object.entries(userTasks).flatMap(([userId, tasks]) =>
    Object.entries(tasks).filter(([_, task]) => task.status === 'approved').map(([taskId, task]) => ({ ...task, userId }))
  );
  
  return (
    <div className="bg-gray-900 min-h-screen p-8">
      <h1 className="text-3xl font-extrabold text-white mb-6">Admin Task Management</h1>

      <button
        className="bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition duration-300 mb-6"
        onClick={() => handleOpenDialog('add')}
      >
        Add New Task
      </button>

      <div className="task-cards grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
        {tasks.map((task) => (
          <div key={task?.id} className="task-card bg-gray-800 p-6 rounded-lg shadow-lg hover:bg-gray-700 transition duration-300">
            <h2 className="text-xl font-semibold text-white mb-4">{task.title}</h2>
            <p className="text-gray-400 mb-4">{task.description}</p>
            <a href={task.taskUrl} className="text-blue-400 mb-4" target="_blank" rel="noopener noreferrer">Task Link</a>
            <p className="text-gray-300 mb-4">Points: {task.points}</p>
            <button
              className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition duration-300 mr-2"
              onClick={() => handleOpenDialog('edit', task)}
            >
              Edit
            </button>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
              onClick={() => handleDeleteTask(task.id)}
            >
              Delete
            </button>
          </div>
        ))}
      </div>

      <h2 className="text-2xl font-extrabold text-white mb-6">Tasks Under Review</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {pendingTasks.map((task) => (
          <div key={`${task.uid}-${task.id}`} className="user-task bg-gray-800 p-6 rounded-lg shadow-lg hover:bg-gray-700 transition duration-300">
            <div className="flex items-center mb-4">
              <Avatar src={users.find(user => user.id === task.userId)?.logo} alt={users.find(user => user.id === task.userId)?.displayName} />
              <div className="ml-4">
                <Typography variant="h6" className="text-white">{users.find(user => user.id === task.userId)?.displayName}</Typography>
                <Typography variant="subtitle1" className="text-gray-400">@{users.find(user => user.id === task.userId)?.[task.type]}</Typography>
              </div>
            </div>
            <h3 className="text-xl font-semibold text-white mb-4">{task.title}</h3>
            <p className="text-gray-400 mb-4">{task.description}</p>
            <a href={task.taskUrl} className="text-blue-400 mb-4" target="_blank" rel="noopener noreferrer">Task Link</a>
            <p className="text-gray-300 mb-4">Points: {task.points}</p>
            <div className='flex justify-between items-center'>
            <button
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300 mr-2"
              onClick={() => handleOpenDialog('review', task)}
            >
              Review
            </button>
            <p className='text-white'>{task.status}</p>
            </div>
          </div>
        ))}
      </div>
      <h2 className="text-2xl font-extrabold text-white mb-6">Tasks Under Clicked</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {clickedTasks.map((task) => (
          <div key={`${task.uid}-${task.id}`} className="user-task bg-gray-800 p-6 rounded-lg shadow-lg hover:bg-gray-700 transition duration-300">
            <div className="flex items-center mb-4">
              <Avatar src={users.find(user => user.id === task.userId)?.logo} alt={users.find(user => user.id === task.userId)?.displayName} />
              <div className="ml-4">
                <Typography variant="h6" className="text-white">{users.find(user => user.id === task.userId)?.displayName}</Typography>
                <Typography variant="subtitle1" className="text-gray-400">@{users.find(user => user.id === task.userId)?.[task.type]}</Typography>
              </div>
            </div>
            <h3 className="text-xl font-semibold text-white mb-4">{task.title}</h3>
            <p className="text-gray-400 mb-4">{task.description}</p>
            <a href={task.taskUrl} className="text-blue-400 mb-4" target="_blank" rel="noopener noreferrer">Task Link</a>
            <p className="text-gray-300 mb-4">Points: {task.points}</p>
            <button
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300 mr-2"
              onClick={() => handleOpenDialog('review', task)}
            >
              Review
            </button>
          </div>
        ))}
      </div>
      <h2 className="text-2xl font-extrabold text-white mb-6">Tasks Completed</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {completedTasks.map((task) => (
          <div key={`${task.uid}-${task.id}`} className="user-task bg-gray-800 p-6 rounded-lg shadow-lg hover:bg-gray-700 transition duration-300">
            <div className="flex items-center mb-4">
              <Avatar src={users.find(user => user.id === task.userId)?.logo} alt={users.find(user => user.id === task.userId)?.displayName} />
              <div className="ml-4">
                <Typography variant="h6" className="text-white">{users.find(user => user.id === task.userId)?.displayName}</Typography>
                <Typography variant="subtitle1" className="text-gray-400">@{users.find(user => user.id === task.userId)?.[task.type]}</Typography>
              </div>
            </div>
            <h3 className="text-xl font-semibold text-white mb-4">{task.title}</h3>
            <p className="text-gray-400 mb-4">{task.description}</p>
            <a href={task.taskUrl} className="text-blue-400 mb-4" target="_blank" rel="noopener noreferrer">Task Link</a>
            <p className="text-gray-300 mb-4">Points: {task.points}</p>
            <button
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300 mr-2"
              onClick={() => handleOpenDialog('review', task)}
            >
              Review
            </button>
          </div>
        ))}
      </div>

      {/* Dialogs */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
  <DialogTitle>
    {dialogType === 'add'
      ? 'Add New Task'
      : dialogType === 'edit'
      ? 'Edit Task'
      : 'Review Task'}
  </DialogTitle>
  <DialogContent>
    {dialogType !== 'review' ? (
      <>
        <TextField
          margin="dense"
          label="Title"
          fullWidth
          value={newTask.title}
          onChange={(e) => setNewTask((prev) => ({ ...prev, title: e.target.value }))}
          variant="outlined"
          className="mb-4"
        />
        <TextField
          margin="dense"
          label="Description"
          fullWidth
          value={newTask.description}
          onChange={(e) => setNewTask((prev) => ({ ...prev, description: e.target.value }))}
          variant="outlined"
          className="mb-4"
        />
        <TextField
          margin="dense"
          label="Task URL"
          fullWidth
          value={newTask.taskUrl}
          onChange={(e) => setNewTask((prev) => ({ ...prev, taskUrl: e.target.value }))}
          variant="outlined"
          className="mb-4"
        />
        <TextField
          margin="dense"
          label="Points"
          fullWidth
          type="number"
          value={newTask.points}
          onChange={(e) => setNewTask((prev) => ({ ...prev, points: e.target.value }))}
          variant="outlined"
          className="mb-4"
        />
        <TextField
          margin="dense"
          label="Type"
          fullWidth
          value={newTask.type}
          onChange={(e) => setNewTask((prev) => ({ ...prev, type: e.target.value }))}
          variant="outlined"
          className="mb-4"
        />
      </>
    ) : (
      <>
        <Typography variant="body1" className="mb-4">Task Title: {reviewTask.taskId}</Typography>
        <Typography variant="body1" className="mb-4">Current Status: {reviewTask.status}</Typography>
        <FormControl fullWidth variant="outlined" margin="dense" className="mb-4">
          <InputLabel>Status</InputLabel>
          <Select
            value={reviewTask.status}
            onChange={(e) => setReviewTask((prev) => ({ ...prev, status: e.target.value }))}
            label="Status"
          >
            <MenuItem value="approved">Approved</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="reviewing">Reviewing</MenuItem>
            <MenuItem value="clicked">Clicked</MenuItem>
            <MenuItem value="failed">Failed</MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          label="Points"
          fullWidth
          type="number"
          value={reviewTask.points}
          onChange={(e) => setReviewTask((prev) => ({ ...prev, points: Number(e.target.value) }))}
          variant="outlined"
          className="mb-4"
        />
        <TextField
          margin="dense"
          label="Description"
          fullWidth
          value={reviewTask.description}
          onChange={(e) => setReviewTask((prev) => ({ ...prev, description: e.target.value }))}
          variant="outlined"
          className="mb-4"
        />
      </>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
    {dialogType === 'add' && <Button onClick={handleAddTask} color="primary">Add</Button>}
    {dialogType === 'edit' && <Button onClick={handleEditTask} color="primary">Update</Button>}
    {dialogType === 'review' && <Button onClick={handleUpdateTaskStatus} color="primary">Update Status</Button>}
  </DialogActions>
</Dialog>

    </div>
  );
};

export default AdminTaskPage;
