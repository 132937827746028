import React, { useState } from 'react';
import { ref as dbRef, set } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { analytics, database, storage } from '../components/firebaseConfig';
import { logEvent } from 'firebase/analytics';

const SubmitProjectPage = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [ownerSocial, setOwnerSocial] = useState('');
  const [link, setLink] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isTokenListed, setIsTokenListed] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState("");
  const [FailureMessage, setFailureMessage] = useState("");
  
  // Additional crypto project parameters
  const [tokenName, setTokenName] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [tokenType, setTokenType] = useState('');
  const [blockchain, setBlockchain] = useState('');
  const [smartContractAddress, setSmartContractAddress] = useState('');
  const [whitepaper, setWhitepaper] = useState('');

  const [submitted, setSubmitted] = useState(false);
  
  const [isOwner, setIsOwner] = useState(false); // New state for checking if the user is the project owner

  const navigate = useNavigate();

  logEvent(analytics, 'Submit Page Visit', {
    firebase_screen: 'Project Submit',
    firebase_screen_class: 'submitpage'
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true)
    setSuccessMessage("")
    setFailureMessage("")

    try {
      const storageReference = storageRef(storage, `project-images/${image.name}`);
      await uploadBytes(storageReference, image);
      const imageUrl = await getDownloadURL(storageReference);

      const newProjectRef = dbRef(database, `projects/${Date.now()}`);
      await set(newProjectRef, {
        name,
        description,
        category,
        owner: isOwner ? {
          name: ownerName,
          social: ownerSocial,
        } : null,
        link,
        likes:0,
        follows:0,
        followers:0,
        rewards:0,
        imageUrl,
        status:"ongoing",
        verified: false,
        approved: false,
        // Additional parameters for crypto projects
        new:true,
        tokenName: isTokenListed ? tokenName : '',
        tokenSymbol: isTokenListed ? tokenSymbol : '',
        tokenType: isTokenListed ? tokenType : '',
        blockchain: isTokenListed ? blockchain : '',
        smartContractAddress: isTokenListed ? smartContractAddress : '',
        whitepaper,
      });
      setSuccessMessage("Your project has been submitted .")
      setTimeout(() => {
        setSubmitted(false)
        navigate('/projects');
      }, 3000);
    } catch (error) {
      setFailureMessage(error.message)
      setSubmitted(false)
      console.error('Error submitting project:', error);
    }
  };

  return (
    <div className="p-4 bg-gray-100 text-black min-h-screen flex justify-center items-center">
      <form onSubmit={handleSubmit} className="w-full max-w-md bg-white p-6 rounded-md shadow-md">
        <h2 className="text-2xl font-bold mb-4">Submit Project</h2>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Project Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 bg-gray-200 text-black rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <textarea
            placeholder="Project Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 bg-gray-200 text-black rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="w-full p-2 bg-gray-200 text-black rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Project Link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            className="w-full p-2 bg-gray-200 text-black rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <input
            type="file"
            onChange={handleImageChange}
            className="w-full p-2 bg-gray-200 text-black rounded-md"
            required
          />
          {imagePreview && (
            <img src={imagePreview} alt="Project Preview" className="mt-4 w-full h-40 object-cover rounded-md" />
          )}
        </div>

        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={isOwner}
              onChange={() => setIsOwner(!isOwner)}
              className="mr-2"
            />
            Are you the project owner?
          </label>
        </div>

        {isOwner && (
          <>
            <div className="mb-4">
              <input
                type="text"
                placeholder="Owner Name"
                value={ownerName}
                onChange={(e) => setOwnerName(e.target.value)}
                className="w-full p-2 bg-gray-200 text-black rounded-md"
                required
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                placeholder="Owner Social Media Handle (e.g., Telegram, Instagram, Discord)"
                value={ownerSocial}
                onChange={(e) => setOwnerSocial(e.target.value)}
                className="w-full p-2 bg-gray-200 text-black rounded-md"
                required
              />
            </div>
          </>
        )}

        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={isTokenListed}
              onChange={() => setIsTokenListed(!isTokenListed)}
              className="mr-2"
            />
            Is your token listed?
          </label>
        </div>

        {isTokenListed && (
          <>
            <div className="mb-4">
              <input
                type="text"
                placeholder="Token Name"
                value={tokenName}
                onChange={(e) => setTokenName(e.target.value)}
                className="w-full p-2 bg-gray-200 text-black rounded-md"
                required
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                placeholder="Token Symbol"
                value={tokenSymbol}
                onChange={(e) => setTokenSymbol(e.target.value)}
                className="w-full p-2 bg-gray-200 text-black rounded-md"
                required
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                placeholder="Token Type (e.g., ERC-20, BEP-20)"
                value={tokenType}
                onChange={(e) => setTokenType(e.target.value)}
                className="w-full p-2 bg-gray-200 text-black rounded-md"
                required
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                placeholder="Blockchain"
                value={blockchain}
                onChange={(e) => setBlockchain(e.target.value)}
                className="w-full p-2 bg-gray-200 text-black rounded-md"
                required
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                placeholder="Smart Contract Address"
                value={smartContractAddress}
                onChange={(e) => setSmartContractAddress(e.target.value)}
                className="w-full p-2 bg-gray-200 text-black rounded-md"
                required
              />
            </div>
          </>
        )}

        <div className="mb-4">
          <input
            type="url"
            placeholder="Whitepaper Link"
            value={whitepaper}
            onChange={(e) => setWhitepaper(e.target.value)}
            className="w-full p-2 bg-gray-200 text-black rounded-md"
            required
          />
        </div>

        <button
          disabled={submitted}
          type="submit"
          className={`w-full ${submitted ?  "bg-gray-500 hover:bg-gray-600"  : "bg-blue-500 hover:bg-blue-600"} py-2 rounded-md focus:outline-none text-white`}
        >
          {submitted ? "Please wait..." : "Submit Project"}
        </button>
        {SuccessMessage && (
          <p className="bg-green-200 text-green-700 px-8 py-4 rounded-lg border-green-600 mt-4 ">{SuccessMessage}</p>
        )}
        {FailureMessage && (
          <p className="bg-red-200 text-red-700 px-8 py-4 rounded-lg border-red-600 mt-4 ">{FailureMessage}</p>
        )}
      </form>
    </div>
  );
};

export default SubmitProjectPage;
