import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
  },
};

Modal.setAppElement("#root");

function PopupDialog({ isOpen, onClose, content }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Popup Dialog"
    >
      <h2 className="font-bold text-center ">{content.title}</h2>
      <p className="mt-1 text-center">{content.message}</p>
      <div className="flex justify-center">
        <button
          className="bg-blue-500 text-white mt-2 text-s text-center px-4 py-1 rounded-lg"
          onClick={onClose}
        >
          Done
        </button>
      </div>
    </Modal>
  );
}

export default PopupDialog;
