import React, { useEffect, useState } from 'react';
import withAuth from './withAuth';
import { signOut } from 'firebase/auth';
import { auth } from '../components/firebaseConfig';
import NumberIncreaseAnimation from '../components/NumberIncreaseAnimation';
import { get, getDatabase, off, onValue, push, ref, update } from 'firebase/database';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material'; // Import MUI components
import { toast } from 'react-toastify';
import Leaderboard from './LeaderBoard';
import { Helmet } from 'react-helmet-async';
import { TonConnect, TonConnectButton, useTonAddress, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import tonConnect from '../modules/connecter';

const TaskPage = () => {
  const { currentUser } = auth;
  const [users, setUsers] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [Userstasks, setUsersTasks] = useState([]);

  const Admin = process.env.REACT_APP_EMAIL; 

  const [dropdownOpen, setDropdownOpen] = useState({signOut:false})
  const toggleList = (menu) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  const [pointsHistory, setPointsHistory] = useState([]);
  const [ClaimHistorys, setClaimHistorys] = useState([]);
  const [visibleItems, setVisibleItems] = useState(5);
  const [visibleItems2, setVisibleItems2] = useState(5);
  const [sortedHistory, setSortedHistory] = useState([]);
  const [claimsortedHistory, setClaimSortedHistory] = useState([]);

  const [account, setAccount] = useState(null);
  
  const tonConnectUI = useTonConnectUI();
  const walletAddress = useTonAddress(true)

  const handleDisconnect = () => {
    tonConnect.disconnect();
    setAccount(null);
  }

  const loadMore = () => {
    setVisibleItems((prev) => prev + 10); // Load 5 more items on each click
  };
  const loadMore2 = () => {
    setVisibleItems2((prev) => prev + 10); // Load 5 more items on each click
  };

  const [taskStatus, setTaskStatus] = useState({});
  const [handleInput, setHandleInput] = useState('');
  const [currentTaskId, setCurrentTaskId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const db = getDatabase();
  const totaluserRef = ref(db, `users/`);
  const pointshistoryRef = ref(db, `pointshistory/${currentUser?.uid}`);
  const usertasksRef = ref(db, `taskshistory/${currentUser?.uid}`);
  const userRef = ref(db, `users/${currentUser?.uid}`);
  const tasksRef = ref(db, `tasks`);
  const claimsRef = ref(db, `claims/`);

  const ShowDialog = (handleType, id) => {
    setCurrentTaskId(id)
    setHandleInput(userDetails[handleType] || '')
    setOpenDialog(true)
  }
  
  // useEffect(() => {
  //   const claimsorted = ClaimHistorys ? [...ClaimHistorys]?.sort((a, b) => new Date(b.date) - new Date(a.date)) : [];
  //   setClaimSortedHistory(claimsorted);
  // }, [ClaimHistorys]);

  useEffect(() => {
    const sorted = pointsHistory ? [...pointsHistory].sort((a, b) => new Date(b.date) - new Date(a.date)) : [];
    setSortedHistory(sorted);
  }, [pointsHistory]);

  useEffect(() => {
    if (currentUser) {
      const handleValueChange = (snapshot) => {
        const data = snapshot.val();
        setUserDetails(data);
      };
      const unsubscribe = onValue(userRef, handleValueChange);

      return () => {
        unsubscribe()
      };
    }
  }, [currentUser]);
  
  useEffect(() => {
    if (currentUser) {
      const handleValueChange = (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setPointsHistory(data);
        }
      };
      const unsubscribe = onValue(pointshistoryRef, handleValueChange, {
        onlyOnce: true,
      });

      return () => {
        unsubscribe()
        off(pointshistoryRef);
      };
    }
  }, [currentUser]);
  
  useEffect(() => {
    if (currentUser) {
      const handleValueChange = (snapshot) => {
        const data = Object.values(snapshot.val()).filter(data => data?.uid === currentUser?.uid);
        if(data){
          setClaimHistorys(data);
        }
      };
      const unsubscribe = onValue(claimsRef, handleValueChange, {
        onlyOnce: true,
      });

      return () => {
        unsubscribe()
        off(claimsRef);
      };
    }
  }, [currentUser]);
  
  useEffect(() => {
    if (currentUser) {
      const handleTotalValueChange = (snapshot) => {
        const data = snapshot.val();
        setUsers(data);
      };
      const unsubscribe = onValue(totaluserRef, handleTotalValueChange, {
      });
      return () => {
        unsubscribe()
        off(totaluserRef);
      };
    }
  }, [currentUser]);

  const getReferralLink = () => {
    let referralLink
    if(auth){
      referralLink = `${window.location.origin}/auth?referrer=${auth?.currentUser?.uid}`;
    }
    return referralLink
  }

  const fetchUserDetails = (userId) => {
    const userRef = ref(db, `users/${userId}`);
    let data
    onValue(userRef, (snapshot) => {
      data = snapshot.val();
    });
    return data
  };

  const copyToClipboard = () => {
    navigator?.clipboard
      ?.writeText(getReferralLink().toString())
      .then(() => {
        toast.success("Referrall Link Copied")
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
        toast.error(error?.message)
        // Handle the error, such as showing an error message to the user
      });
  };

  useEffect(() => {
    const handleTasksChange = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setTasks(Object.entries(data).map(([id, task]) => ({ id, ...task })));
      }
    };
    const unsubscribe = onValue(tasksRef, handleTasksChange);

    return () => {
      unsubscribe()
      off(tasksRef);
    };
  }, []);
  
  useEffect(() => {
    const handleTasksChange = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setUsersTasks(Object.entries(data).map(([id, task]) => ({id, ...task })));
      }
    };
    const unsubscribe = onValue(usertasksRef, handleTasksChange);

    return () => {
      unsubscribe()
      off(usertasksRef);
    };
  }, []);


  const handleTaskClick = (taskId, taskUrl) => {
  setCurrentTaskId(taskId)
  const now2 = new Date().toISOString();
  const refUid = userDetails?.referrer || null;
  const Myreferrer = Object.values(users).find(user => user.uid === refUid);
  // const pointsHistoryRef = ref(db, `pointsHistory/${userDetails?.uid}`);
  
  if (Userstasks.length === 0 && refUid && Myreferrer) {
    const pointsRef = Myreferrer?.points + 100;

    // Get the current points history and push the new entry
    get(ref(db, `pointshistory/${refUid}`)).then(snapshot => {
      const currentHistory = snapshot.val() || [];
      const newHistory = [
        ...currentHistory,
        { date: now2, points: 100, description: `${userDetails?.displayName} Joined Dailycashloot` },
      ];

      // Update points history with an object containing the new array
      update(ref(db, `users/${refUid}`), { 
        points: pointsRef 
      });
      update(ref(db, `pointshistory/${refUid}`), {
        ...newHistory,
      });
    });
  }
  if (taskStatus[taskId] !== 'clicked') {
    const now = new Date().toISOString().replace(/[:.]/g, '-');
    // Update the task information for the current user
    update(ref(db, `taskshistory/${currentUser?.uid}/${taskId}`), {
      ...tasks.find(task => task.id === taskId),
      uid: currentUser.uid,
      timestamp: now,
      TID: Userstasks.length,
      status: 'clicked'
    });
  }

  // Open the task URL in a new tab
  window.open(taskUrl, '_blank');
  
  // Set the task status to 'clicked'
  setTaskStatus((prev) => ({ ...prev, [taskId]: 'clicked' }));
};


  const handleSubmitHandle = () => {
    if(currentTaskId){
      const task = tasks.find(task => task.id === currentTaskId);
      const handleType = task?.type || '';
      if (handleInput) {
        setUserHandle(currentUser.uid, currentTaskId, handleType, handleInput);
        updateTaskStatus(currentTaskId, 'reviewing');
        setHandleInput('');
        setOpenDialog(false);
      }else{
        toast.info("Invalid Handle")
      }
    }else{
      toast.info("Unable to Verify Task Completion")
    }
  };

  const setUserHandle = (uid, taskId, handleType, handle) => {
    update(ref(db, `users/${uid}`), { [handleType]: handle });
    update(ref(db, `taskshistory/${uid}/${taskId}/handles`), { [handleType]: handle });
  };

  const updateTaskStatus = (taskId, status) => {
    update(ref(db, `taskshistory/${currentUser.uid}/${taskId}`), { status });
    setTaskStatus((prev) => ({ ...prev, [taskId]: status }));
  };

  const claimReward = (amount) =>{
    try {
      if(walletAddress){
        if(amount>0.01){
          const amt = amount
          const Balance = userDetails?.toncoin
          const user = currentUser?.uid
          const now = new Date().toISOString();
          const key = push(claimsRef).key
          // console.log(key)
          update(claimsRef, {[key]:{
          ...userDetails, 
          date:now,
          amount:amount,
          walletAddress:walletAddress,
          key:key
        }})
        update(userRef, {
          toncoin:Balance-amt
        })
        toast.success("Claim Request Submitted 😊...it will be processed withing 24-48 hours 🚀")
        }else{
          toast.error('Insufficiant Balance 😢. Join our Telegram Channel for New Tasks 😄')
        }
      }else{
        toast.info("Kindy Connect your Wallet to Claim your Rewards")
      }
    } catch (error) {
      toast.error("Something went wrong 😢")
      console.log(error)
    }
  }

  // Filtering tasks based on participation
  const participatedTaskIds = Object.values(Userstasks || {});
  const nonParticipatedTasks = tasks.filter(task => 
    !Userstasks.some(userTask => userTask.id === task.id)
  );
  const approved = Userstasks.filter(task => task.status === 'approved');
  const pending = Userstasks.filter(task => task.status === 'reviewing' || task.status ==='pending' || task.status ==='clicked');

  return (
    <div className="bg-gray-900 min-h-screen flex flex-col items-center">
      <Helmet>
        <title>Task & Earn | Dailycashloot - Task & Crypto</title>
        <meta name="description" content={'Complete Task to Earn Crown Points and Convert it Crypto Token'} />
        <meta name="keywords" content={'task, earn, quests, airdrops, crown, dcl, wallet, defi, decentralised'} />
        {/* Other meta tags as needed */}
      </Helmet>
      <header className='flex w-full text-sm justify-between text-white px-6 py-6 items-center'>
        <div className='relative'>
        <button onClick={() => toggleList('tasks')}  className='flex items-center bg-gray-800 hover:bg-gray-700 transition duration-300 px-4 py-2 rounded-xl'>
          <img className='rounded-full h-6 w-6' src={currentUser?.photoURL} alt='userLogo'/>
          <p className='ml-2'>{currentUser?.displayName}</p>
        </button>
        {dropdownOpen.tasks && (
            <div className='absolute left-0 mt-2 bg-white text-gray-900 px-8 py-2 rounded-xl shadow-lg'>
              <button 
                className='w-full text-left text-sm text-nowrap font-sans font-bold'
                onClick={() => {
                  signOut(auth);
                  setDropdownOpen(false);
                }}
              >
                Sign Out
              </button>
            </div>
          )}
        </div>
        {/* <button className={'bg-blue-600 px-4 py-2 rounded-xl text-sm font-sans font-bold'} onClick={() => signOut(auth)}>Sign Out</button> */}
        <div className='md:flex justify-center items-center space-x-2'>
        {!account ? (
        <TonConnectButton className='ton-connect' tonConnectUI={tonConnectUI} />
      ) : (
        <div>
          <h2>Connected Account:</h2>
          <p>{account.address}</p>
          <button onClick={handleDisconnect}>Disconnect</button>
        </div>
      )}
        </div>
      </header>
      <div className='flex flex-col px-6 mx-auto w-full'>
        <div className='flex rounded-xl bg-gray-800 w-full text-white font-sans font-extrabold p-6 md:p-8'>
          <div className='flex-col w-full'>
          <div className='flex text-center text-xs md:text-2xl'>
          <p className=' '>Crown Points</p>
          <p className='ml-2 md:mt-2 md:ml-4 text-xs md:text-sm  font-bold mb-3 bg-blue-100 px-4 text-blue-600 rounded-full'>
            Beta
          </p>
        </div>
            <div className='text-xl md:text-4xl text-green-500'>
              {userDetails?.points !== undefined ?
                <NumberIncreaseAnimation decimals={0} start={0} end={userDetails.points} duration={2.5} prefix="" suffix=" CP" /> :
                <NumberIncreaseAnimation decimals={0} start={0} end={0} duration={2.5} prefix="" suffix=" CP" />
              }
            </div>
          </div>
          <div className='flex items-center'>
            <button className='bg-gray-600 text-xs hover:bg-gray-500 transition duration-300 md:text-sm px-4 md:px-8 rounded-xl py-2'>Convert</button>
          </div>    
        </div>
        <div className='flex rounded-xl mt-4 bg-gray-800 w-full text-white font-sans font-extrabold p-6 md:p-8'>
          <div className='flex-col w-full'>
          <div className='flex items-center text-center text-xs md:text-2xl mb-3'>
          <img src={'/ton_logo.png'} alt='ton_logo' className='h-5 md:h-8'/><span className='ml-2 font-bold text-xs md:text-2xl'>Rewards</span>
          <p className='ml-2 md:mt-2 md:ml-4 text-xs md:text-sm font-bold bg-blue-100 px-4 text-blue-600 rounded-full'>
            Beta
          </p>
        </div>
              <div className='text-xl md:text-3xl text-white'>
            
              {userDetails?.toncoin !== undefined ?
              <div className='flex'>
                <NumberIncreaseAnimation start={0} end={userDetails.toncoin} duration={2.5} prefix="" suffix=" TON" decimals={4} /> 
              </div>
                :
                <div className='flex items-center'>
                <NumberIncreaseAnimation start={0} end={0} decimals={0} duration={2.5} prefix="" suffix=" TON" />
                </div>
              }
            </div>
          </div>
          <div className='flex items-center'>
            <button onClick={()=>claimReward(userDetails?.toncoin)} className={`${walletAddress ? 'bg-blue-600 hover:bg-blue-500':'bg-gray-600 hover:bg-gray-500'} text-xs transition duration-300 md:text-sm px-4 md:px-8 rounded-xl py-2`}>Claim</button>
          </div>    
        </div>
          {/* Show Claims History here it Must be Stored under the user details */}
          <div className="flex rounded-xl bg-gray-800 w-full text-white mt-4 font-sans p-6 md:p-8 flex-col">
      <h2 className="text-sm md:text-2xl font-bold mb-4">Claims History</h2>
    
      {ClaimHistorys.length > 0 ? (
        <>
          <div className="space-y-4">
            {ClaimHistorys.slice(0, visibleItems2).map((entry, index) => (
              <div
                key={index}
                className="bg-gray-700 p-4 text-xs md:text-xl rounded-xl flex justify-between items-center"
              >
                <div>
                  <p className="font-semibold">{entry?.description || "LeaderBoard Rewards Claim"}</p>
                  <p className="text-gray-400">{new Date(entry?.date).toLocaleString()}</p>
                </div>
                <div className='flex justify-center items-center'>
                <p
                  className={`text-nowrap md:text-lg font-bold text-red-500`}
                >
                  -{entry?.toncoin}</p>
                  <img src={'/ton_logo.png'} alt='ton_logo' className='h-4 md:h-6 ml-1'/> 
              </div>
                </div>
            ))}
          </div>

          {visibleItems2 < claimsortedHistory?.length && (
            <button
              onClick={loadMore2}
              className="mt-4 bg-gray-600 text-xs md:text-sm hover:bg-gray-500 transition duration-300 px-6 py-2 rounded-xl"
            >
              Load More
            </button>
          )}
        </>
      ) : (
        <p className="text-gray-400">No Claim history available.</p>
      )}
    </div>
          {/* Show Points History here it Must be Stored under the user details */}
          <div className="flex rounded-xl bg-gray-800 w-full text-white mt-4 font-sans p-6 md:p-8 flex-col">
      <h2 className="text-sm md:text-2xl font-bold mb-4">Points History</h2>
      {sortedHistory.length > 0 ? (
        <>
          <div className="space-y-4">
            {sortedHistory.slice(0, visibleItems).map((entry, index) => (
              <div
                key={index}
                className="bg-gray-700 p-4 text-xs md:text-xl rounded-xl flex justify-between items-center"
              >
                <div>
                  <p className="font-semibold">{entry.description}</p>
                  <p className="text-gray-400">{new Date(entry.date).toLocaleString()}</p>
                </div>
                <p
                  className={`text-nowrap md:text-lg font-bold ${
                    entry.points > 0 ? 'text-green-500' : 'text-red-500'
                  }`}
                >
                  {entry.points > 0 ? `+${entry.points}` : entry.points} CP
                </p>
              </div>
            ))}
          </div>

          {visibleItems < sortedHistory.length && (
            <button
              onClick={loadMore}
              className="mt-4 bg-gray-600 text-xs md:text-sm hover:bg-gray-500 transition duration-300 px-6 py-2 rounded-xl"
            >
              Load More
            </button>
          )}
        </>
      ) : (
        <p className="text-gray-400">No points history available.</p>
      )}
    </div>

      </div>
      <div className="container mx-auto p-6 max-w-6xl">
        <h1 className="text-3xl font-extrabold text-white text-center mb-6">Task & Earn Rewards</h1>
        <p className="text-lg text-white text-center mb-6">
          Welcome! Complete the tasks below to earn rewards. Each task will earn you points, which will be converted to DCL Crown tokens.
        </p>
        <h2 className="text-2xl font-bold text-white mb-4">Available Tasks</h2>
        
        {/* Tasks Not Started */}
        {nonParticipatedTasks.length >0 ? <div>
        <div className="task-cards grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          {nonParticipatedTasks.map((task) => (
            <div key={task.id} className="task-card bg-gray-800 p-6 rounded-lg shadow-lg hover:bg-gray-700 transition duration-300">
              <h2 className="text-xl font-semibold text-white mb-4">{task.title}</h2>
              <p className="text-gray-400 mb-4">{task.description}</p>
              <div className='flex'>
              <p className="bg-green-600 text-white rounded-full px-4 font-sans font-bold text-sm mb-4">{task.points} CP</p>
              </div>
              <button
                className={`bg-blue-500 text-white px-4 py-2 rounded-xl hover:bg-blue-600 transition duration-300`}
                onClick={() => handleTaskClick(task.id, task.taskUrl)}
              >
                Open Link
              </button>
            </div>
          ))}
        </div></div>: <p className='flex text-center w-full justify-center items-center text-white min-h-48'>Currently There is No Available Tasks Found. Come again later for New Tasks</p> }

        {/* Tasks Reviewing */}
        {pending.length > 0 && <div><h2 className="text-2xl font-bold text-white mb-4">Pending Tasks</h2>
        <div className="task-cards grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          {pending.map((task) => (
            <div key={task.id} className="task-card bg-gray-800 p-6 rounded-lg shadow-lg hover:bg-gray-700 transition duration-300">
              <h2 className="text-xl font-semibold text-white mb-4">{task.title}</h2>
              <p className="text-gray-400 mb-4">{task.description}</p>
              <div className='flex'>
              <p className="bg-green-600 text-white rounded-full px-4 font-sans font-bold text-sm mb-4">{task.points} CP</p>
              </div>
              {task.status !== 'reviewing' ? <div className='flex justify-between'>
              <button
                className={`bg-blue-500 text-white text-sm px-4 py-2 rounded-xl hover:bg-blue-600 transition duration-300`}
                onClick={()=> handleTaskClick(task.id, task.taskUrl)}
              >
                Open Task
              </button>
             {currentTaskId && <button
                className={`bg-blue-500 text-white text-sm px-4 py-2 rounded-xl hover:bg-blue-600 transition duration-300`}
                onClick={()=> ShowDialog(task?.type, task?.id)}
              >
                Complete
              </button>}
              </div>: <div >
                <button
                className={`bg-gray-500 text-white text-sm px-4 py-2 rounded hover:bg-gray-600 transition duration-300`}
                disabled
              >
                UnderReview
              </button></div>}
            </div>
          ))}
        </div></div> }

        {/* Tasks Completed */}
        {approved.length > 0 && <div><h2 className="text-2xl font-bold text-white mb-4">Completed</h2>
        <div className="task-cards grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {approved.map((task) => (
            <div key={task.id} className="task-card bg-gray-800 p-6 rounded-lg shadow-lg hover:bg-gray-700 transition duration-300">
              <h2 className="text-xl font-semibold text-white mb-4">{task.title}</h2>
              <p className="text-gray-400 mb-4">{task.description}</p>
              <div className='flex'>
              <p className="bg-green-600 text-white rounded-full px-4 font-sans font-bold text-sm mb-4">{task.points} CP</p>
              </div>
              <button
                className={`bg-green-500 text-white  text-sm px-4 py-2 rounded-xl hover:bg-green-600 transition duration-300`}
                disabled
              >
                Task Completed
              </button>
            </div>
          ))}
        </div></div>}
      </div>
      <div className='flex px-6 container md:mx-auto md:max-w-6xl w-full justify-center items-center'>
      <section className="container text-center  mb-4 bg-gray-800 p-8 rounded-lg shadow-lg">     
      <h2 className="text-xl md:text-xl text-white font-sans font-bold underline mb-4">Referral Link</h2>
      <div className="flex items-center text-xs mb-2 ">
        <input
          type="text"
          value={getReferralLink()}
          readOnly
          className="flex-1 bg-gray-100 border text-gray-800 border-gray-300 rounded-lg px-4 py-2 mr-3 focus:outline-none focus:ring-2 focus:ring-green-500"
        />
        <button
          className="text-nowrap bg-green-500 hover:bg-green-600 text-white py-2 px-6 rounded-lg font-semibold transition-transform transform hover:scale-105"
          onClick={copyToClipboard}
        >
          Copy
        </button>
      </div>
        <div className='text-white'>
          Share this link with your friends and get 100 CP (Crown Points) !
          </div>
    </section>
    </div>
    <Leaderboard users={users} currentUserId={userDetails}  />

      {/* Dialog for handle submission */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Submit Handle</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Handle"
            type="text"
            fullWidth
            value={handleInput}
            onChange={(e) => setHandleInput(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={() => handleSubmitHandle()}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withAuth(TaskPage);
