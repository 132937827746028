import React, { useState, useEffect } from "react";
import PopupDialog from "./Modal";
import {
  TokensABI,
  ReferralsABI,
  TokensAddress,
  ReferralsAddress,
} from "./utils/constants";

const { ethers } = require("ethers");

const ReferralContainer = ({ props }) => {
  const {
    IsConnected,
    ChainID,
    account,
    requestAccounts,
    showDialog,
    signer,
    provider,
  } = props;

  const [MaticBalance, setMaticBalance] = useState(0);
  //Balance & Referral Tracking
  const [accountExists, setaccountExists] = useState(false);
  const [balance, setBalance] = useState(0);
  const [Tokenbalance, setTokenbalance] = useState(0);

  const [UserBalance, setUserBalance] = useState(0);
  const [ReferralBalance, setReferralBalance] = useState(0);
  const [referralCount, setReferralCount] = useState(0);
  const [inviterAddress, setInviterAddress] = useState("");
  const [isInvitedUser, setisInvitedUser] = useState(false);
  // Rewards Tracking
  const [newUserClaimable, setnewUserClaimable] = useState(false);
  const [referralClaimable, setreferralClaimable] = useState(false);
  //Fee and Rewards Details
  const [RegistrationFee, setRegistrationFee] = useState(0);
  const [newUserRewards, setnewUserRewards] = useState(0);
  const [referralReward, setReferralReward] = useState(0);
  const [TokenAllowances, setTokenAllowances] = useState(0);
  const [showCopyButton, setShowCopyButton] = useState(false);

  function shortenAddress(chars = 6) {
    if (!account) return "";
    return account.slice(0, chars) + "..." + account.slice(-chars);
  }

  const referralLink = `https://dailycashloot.fun/Referrals?address=${account}`;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const inviter = params.get("address");
    if (inviter) {
      setInviterAddress(inviter);
      setisInvitedUser(true);
    }
    const fetchData = async () => {
      if (IsConnected && account) {
        try {
          const Referralcontract = new ethers.Contract(
            ReferralsAddress,
            ReferralsABI,
            signer
          );
          const TokensContract = new ethers.Contract(
            TokensAddress,
            TokensABI,
            signer
          );

          const accExist = await Referralcontract.accountsExist(account);
          setaccountExists(accExist);

          const referralclaimEnabled =
            await Referralcontract.referralClaimEnabled();
          setreferralClaimable(referralclaimEnabled);

          const newUserclaimEnabled =
            await Referralcontract.newUserClaimEnabled();
          setnewUserClaimable(newUserclaimEnabled);

          const bal = await Referralcontract.newUserClaimableRewards(account);
          setUserBalance(ethers.utils.formatEther(bal));

          const registerFee = await Referralcontract.registrationFee();
          setRegistrationFee(ethers.utils.formatEther(registerFee));

          const referralBal = await Referralcontract.referralClaimableRewards(
            account
          );
          setReferralBalance(ethers.utils.formatEther(referralBal));

          const totalBal = await Referralcontract.balances(account);
          setBalance(ethers.utils.formatEther(totalBal));

          const newUsersreward = await Referralcontract.newUserReward();
          setnewUserRewards(ethers.utils.formatEther(newUsersreward));

          const referralsreward = await Referralcontract.referalReward();
          setReferralReward(ethers.utils.formatEther(referralsreward));

          const referralCountVal = await Referralcontract.referralCount(
            account
          );
          setReferralCount(ethers.BigNumber.from(referralCountVal).toString());

          // this is for Token Contract Interaction
          const allowances = await TokensContract.allowance(
            account,
            ReferralsAddress
          );
          setTokenAllowances(ethers.utils.formatEther(allowances));

          const Tokenbal = await TokensContract.balanceOf(account);
          setTokenbalance(ethers.utils.formatEther(Tokenbal));
        } catch (error) {
          console.error("Error fetching data in useEffect:", error);
        }
      }
    };

    fetchData();
  }, [
    IsConnected,
    account,
    referralClaimable,
    newUserClaimable,
    balance,
    UserBalance,
    ReferralBalance,
    referralCount,
    TokenAllowances,
    accountExists,
    RegistrationFee,
    accountExists,
    Tokenbalance,
    signer,
    provider,
  ]);

  async function ClaimUserBalance() {
    if (ethers.utils.parseEther(UserBalance).toString() > 0) {
      if (newUserClaimable) {
        try {
          const Referralscontract = new ethers.Contract(
            ReferralsAddress,
            ReferralsABI,
            signer
          );
          const bal = await Referralscontract.claimNewUserReward(
            ethers.utils.parseEther(UserBalance).toString()
          );
          bal.wait();
          setUserBalance(0);
          showDialog(
            "Transaction Broadcasted",
            "Confirmation from the Blockchain is Pending..."
          );
        } catch (error) {
          console.error("Error in Claiming User Balance:", error);
          showDialog(
            "Unable to Fetch User Balance",
            "Error fetching user balance"
          );
        }
      } else {
        showDialog(
          "Unable to Claim Reward ",
          "New User Reward Claim is Not Enabled yet"
        );
      }
    } else {
      showDialog(
        "Insufficiant Balance",
        "User Doesn't Have a Enough Balance to Claim"
      );
    }
  }
  async function ClaimReferralBalance() {
    if (ethers.utils.parseEther(ReferralBalance).toString() > 0) {
      if (referralClaimable) {
        try {
          const Referralscontract = new ethers.Contract(
            ReferralsAddress,
            ReferralsABI,
            signer
          );
          const bal = await Referralscontract.claimreferralReward(
            ethers.utils.parseEther(ReferralBalance).toString()
          );
          bal.wait();
          setReferralBalance(0);
          showDialog(
            "Transaction Broadcasted",
            "Confirmation from the Blockchain is Pending..."
          );
        } catch (error) {
          showDialog(
            "Unable to Claim Referal Balance",
            "User Doesn't Have a Enough Balance to Claim"
          );
          console.error("Error fetching user balance:", error);
        }
      } else {
        showDialog(
          "Unable to Claim Reward ",
          "Referral Reward Claim is Not Enabled yet"
        );
      }
    } else {
      showDialog(
        "Insufficiant Balance",
        "User Doesn't Have a Enough Balance to Claim"
      );
    }
  }

  const CheckUserAccount = async (accountaddress) => {
    if (isValidEthereumAddress(accountaddress)) {
      try {
        const Referralscontract = new ethers.Contract(
          ReferralsAddress,
          ReferralsABI,
          provider
        );
        const accExist = await Referralscontract.accountsExist(accountaddress);
        return { accExist };
      } catch (error) {
        showDialog(
          "Error Fetching Account Details",
          "Check your Network Connection or Refresh the Browser"
        );
        console.error("Error checking Users Account:", error);
        return { accExist: false };
      }
    } else {
      return { accExist: false };
    }
  };

  const ApproveToken = async (amount) => {
    try {
      const TokensContract = new ethers.Contract(
        TokensAddress,
        TokensABI,
        signer
      );
      const accExist = await TokensContract.approve(ReferralsAddress, amount);
      accExist.wait();
      showDialog(
        "Transaction Broadcasted",
        "Confirmation from the Blockchain is Pending..."
      );
      setTokenAllowances(0);
      return { accExist };
    } catch (error) {
      showDialog(
        "Token Approval Failed !!!",
        "Unable to Approve $Crown Token Spending"
      );
      console.error("Error Approving Token Spending:", error);
      return { accExist: 0 };
    }
  };

  async function RegisterAccount() {
    if (isValidEthereumAddress(inviterAddress)) {
      if (CheckUserAccount(inviterAddress)) {
        if (Tokenbalance >= RegistrationFee) {
          if (TokenAllowances >= RegistrationFee) {
            try {
              const Referralscontract = new ethers.Contract(
                ReferralsAddress,
                ReferralsABI,
                signer
              );
              CheckUserAccount(inviterAddress);
              const registerAccount = await Referralscontract.registerAccount(
                inviterAddress
              );
              const tx = registerAccount.wait();
              showDialog(
                "Transaction Broadcasted",
                "Confirmation from the Blockchain is Pending..."
              );
              setaccountExists(true);
            } catch (error) {
              console.error(
                "Error Occurred while Registering your Account: ",
                error
              );
              showDialog(
                "Unable to Register",
                "The Transaction is Either Failed or Cancelled. Kindly use Aggressive gas for Faster Transaction if the Problem Persists."
              );
            }
          } else {
            ApproveToken(ethers.utils.parseEther(RegistrationFee).toString());
          }
        } else {
          showDialog(
            "Insufficiant Balance",
            `${RegistrationFee} $Crown Token is Required for Registration`
          );
        }
      } else {
        setisInvitedUser(false);
        showDialog(
          "Invalid Address",
          "This address is not Registered in our Platform"
        );
      }
    } else {
      setisInvitedUser(false);
      showDialog(
        "Invalid Address",
        "Please Enter a Valid Referral Address. if you Dont Referral Address Get it from Our Official Telegram Channel or on Discord Server "
      );
    }
  }

  function isValidEthereumAddress(address) {
    // Regular expression to match Ethereum addresses
    const regex = /^(0x)?[0-9a-fA-F]{40}$/;

    // Check if the address matches the regex pattern
    return regex.test(address);
  }
  const handleCopyReferralCode = () => {
    alert("Referral code copied!");
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        console.log("Text copied to clipboard:", referralLink);
        showDialog(
          "Referral Link Copied",
          "Your Referral Link has Been Copied to ClipBoard "
        );
        // You can show a success message or perform other actions here
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
        showDialog(
          "Unable to Copy",
          "This Browser Doesn't Supports Copying Texts "
        );
        // Handle the error, such as showing an error message to the user
      });
  };

  return (
    <>
      <div className="container flex-col justify-center min-h-screen bg-white mx-auto min-w-full px-4 pb-8">
        <div className="min-w-full p-4">
          <div className="flex justify-between items-center">
            <h1 className=" flex-auto text-xl font-bold text-start">
              Referral Program
            </h1>
          </div>
        </div>
        {/* // for User Information */}
        {IsConnected ? (
          !accountExists ? (
            // Account Registerting properties
            <div className="container bg-gray-900 rounded-2xl min-w-full px-8 pt-4 pb-4 shadow-md flex-col justify-between justify-center items-center mb-8">
              <div className="flex-col justify-center items-center py-4">
                <p className="font-bold text-white text-center text-lg">
                  Register an Account
                </p>
                <div className="flex justify-center items-center mt-4">
                  <input
                    type="text"
                    id="inviterAddress"
                    name="inviterAddress"
                    placeholder="Enter inviter's address"
                    value={inviterAddress}
                    onChange={(e) => setInviterAddress(e.target.value)}
                    className="flex block w-full px-3 py-2 border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                invalid:border-pink-500 invalid:text-pink-600
                focus:invalid:border-pink-500 focus:invalid:ring-pink-500 w-full px-4 rounded-md shadow-sm focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                    disabled={isInvitedUser}
                  />
                  <button
                    className="flex py-3 px-8 rounded-lg bg-blue-700 hover:bg-blue-800 text-xs text-bold text-white"
                    onClick={RegisterAccount}
                  >
                    {TokenAllowances >= RegistrationFee
                      ? "Register"
                      : "Approve"}
                  </button>
                </div>
                <p className="text-center text-green-500 mt-4 font-bold">
                  {TokenAllowances >= RegistrationFee
                    ? ""
                    : "Insufficiant Token Allowance. Approve $Crown Token Spending for Registration"}
                </p>
                <div className="flex mt-2 text-s text-center text-white">
                  <p>
                    Register today with a nominal fee of 10,000 $Crown and
                    kickstart your journey with an exclusive Registration Bonus
                    of 100 $Crown. But that's not all - invite your friends and
                    family to join, and for every successful referral, you'll
                    earn a whopping 2,500 $Crown!
                  </p>
                </div>
              </div>
            </div>
          ) : (
            // Token Information on Wallet Connected
            <div className="container bg-gradient-to-br from-red-600 to-red-300 rounded-2xl min-w-full px-8 pt-8 pb-8 shadow-md flex-col justify-between justify-center items-center mb-8">
              <p className=" text-center text-white font-bold">
                Account Details
              </p>
              <div className=" min-w-full bg-white p-4 mt-4 px-4 py-2 justify-center rounded-2xl shadow-md text-center">
                <p className=" font-bold">Total Balance: {balance} $Crown</p>
              </div>
              <div className="mt-4 mb-4 justify-center">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-2">
                  <div className="w-full rounded-2xl shadow-md p-4 bg-white px-8 py-4 flex-col justify-center text-center items-center">
                    <p className=" font-bold text-s">User Balance</p>
                    <p className=" font-bold text-xl">{UserBalance} $Crown</p>
                    <button
                      className={`py-2 px-8 mt-2 font-bold rounded-lg text-white text-xs text-bold  ${
                        newUserClaimable
                          ? `bg-green-600 hover:bg-green-800`
                          : `bg-slate-500 hover:bg-slate-600`
                      }`}
                      onClick={ClaimUserBalance}
                    >
                      Claim
                    </button>
                  </div>
                  <div className="w-full rounded-2xl shadow-md  p-4 bg-white px-8 py-4 flex-col justify-center text-center items-center">
                    <p className="font-bold text-s">Referral Balance</p>
                    <p className="font-bold text-xl">
                      {ReferralBalance} $Crown
                    </p>
                    <button
                      className={`py-2 px-8 mt-2 rounded-lg text-xs font-bold text-white ${
                        referralClaimable
                          ? `bg-green-600 hover:bg-green-800`
                          : `bg-slate-500 hover:bg-slate-600`
                      }`}
                      onClick={ClaimReferralBalance}
                    >
                      Claim
                    </button>
                  </div>
                </div>
              </div>
              <div className="bg-white md:mt-4 px-4 py-2 justify-center rounded-2xl shadow-md text-center">
                <p className=" font-bold">
                  Total Referral Count: {referralCount}
                </p>
              </div>
            </div>
          )
        ) : (
          // Account Not Exist and Wallet Not Connected
          <></>
        )}
        {/* Information Container*/}
        {IsConnected ? (
          <div className="container bg-gradient-to-br from-blue-800 to-blue-300 rounded-2xl min-w-full px-8 pt-8 pb-8 shadow-md flex-col justify-between justify-center items-center mb-8">
            <p className="mb-4 text-white font-bold text-center">
              Token Information
            </p>
            <div className="flex">
              <div className="container flex-col min-w-full rounded-lg bg-white p-4 font-bold text-s text-center  ">
                <p>Crown Holding Balance</p>
                {Tokenbalance} $Crown
              </div>
            </div>
            <div className="container flex-col min-w-full rounded-lg bg-white p-4 font-bold text-s mt-4 text-center  ">
              <p>RegistrationFee</p>
              {RegistrationFee} $Crown
            </div>
            <div className="flex mt-4">
              <div className="container flex-col rounded-lg bg-white mr-4  p-4 font-bold text-s text-center  ">
                <p>Registration Reward</p>
                {newUserRewards} $Crown
              </div>
              <div className="container flex-col rounded-lg bg-white p-4 font-bold text-s text-center  ">
                <p>Referral Reward</p>
                {referralReward} $Crown
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="flex container mx-auto py-4">
          <div className="container min-w-full bg-gradient-to-br from-green-600 to-green-300 rounded-2xl px-8 pt-8 pb-8 shadow-md flex flex-col justify-between justify-center items-center mb-8">
            <img src="/refer.png" alt="refer" className="h-14 w-14 mr-2" />
            <p className="flex justify-center text-white mt-4 font-bold text-lg">
              Refer & Earn
            </p>
            <p className="flex mt-2 text-center text-white">
              Register today with a nominal fee of{" "}
              {RegistrationFee ? RegistrationFee : 10000} $Crown and kickstart
              your journey with an exclusive Registration Bonus of{" "}
              {newUserRewards ? newUserRewards : 1000} $Crown. But that's not
              all - invite your friends and family to join, and for every
              successful referral, you'll earn a whopping{" "}
              {referralReward ? referralReward : 2500} $Crown!
            </p>
          </div>
        </div>
        {/* Referral Link Section */}
        {IsConnected ? (
          accountExists ? (
            <div className="container mx-auto py-4">
              <div className="bg-gradient-to-br from-blue-800 to-blue-300 rounded-lg shadow-md mb-8 p-6">
                <h2 className="text-xl text-white font-bold mb-4">
                  Your Referral Link:
                </h2>
                <div className="flex items-center">
                  <input
                    type="text"
                    value={referralLink}
                    readOnly
                    className="flex-1 bg-gray-100 border border-gray-300 rounded-md px-3 py-2 mr-2 focus:outline-none"
                  />
                  <button
                    className="flex-none bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg"
                    onClick={copyToClipboard}
                  >
                    Copy Link
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        <div className="container mx-auto py-4">
          <div className="bg-gradient-to-br from-red-600 to-red-300 shadow-lg rounded-lg p-6 mb-4">
            <h2 className="text-xl text-white font-bold mb-2 underline">
              Here's how it works
            </h2>
            <div className="flex flex-col space-y-4">
              <div className="flex items-center mt-2">
                <div className="h-8 w-8 flex-none rounded-full bg-indigo-500 flex items-center justify-center">
                  <span className="text-white font-bold">1</span>
                </div>
                <div className="ml-4">
                  <h3 className="text-lg text-white font-bold">Register</h3>
                  <p className="text-white">
                    Register with us for{" "}
                    {RegistrationFee ? RegistrationFee : 10000} $Crown and
                    instantly receive a welcome bonus of{" "}
                    {newUserRewards ? newUserRewards : 1000} $Crown.
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="h-8 w-8 flex-none rounded-full bg-indigo-500 flex items-center justify-center">
                  <span className="text-white font-bold">2</span>
                </div>
                <div className="ml-4">
                  <h3 className="text-lg text-white font-bold">Share </h3>
                  <p className="text-white">
                    Share your Referral link with your network.
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="h-8 w-8 flex-none rounded-full bg-indigo-500 flex items-center justify-center">
                  <span className="text-white font-bold">3</span>
                </div>
                <div className="ml-4">
                  <h3 className="text-lg text-white font-bold">Earn</h3>
                  <p className="text-white">
                    For each person who registers through your link, you'll earn{" "}
                    {referralReward ? referralReward : 2500} $Crown!
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="h-8 w-8 flex-none rounded-full bg-indigo-500 flex items-center justify-center">
                  <span className="text-white font-bold">4</span>
                </div>
                <div className="ml-4">
                  <h3 className="text-lg text-white font-bold">More Rewards</h3>
                  <p className="text-white">
                    There's no limit to how many referrals you can make, so the
                    more you refer, the more you earn.
                  </p>
                </div>
              </div>
              {/* <div className="flex items-center">
                <div className="h-8 w-8 flex-none rounded-full bg-indigo-500 flex items-center justify-center">
                  <span className="text-white font-bold">5</span>
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-bold">
                    Social Media Farming (Coming Soon...)
                  </h3>
                  <p className="text-gray-700">
                    Farming $Crown by Social Media Engagement . incentivizes
                    users to engage with the DCL CROWN Token community on
                    platforms like Twitter(X), Telegram, and Discord.
                  </p>
                </div>
              </div> */}

              {/* Add more milestones as needed */}
            </div>
          </div>
          <div className="bg-gradient-to-br from-indigo-800 to-indigo-300 shadow-lg rounded-lg p-6 mt-12">
            <p className=" text-center font-bold text-white text-sm">
              Join our Refer & Earn Program today and start earning rewards with
              every referral! Happy earning with Dailycashloot Crown Token!"
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralContainer;
