// tonConnect.js
import { TonConnect } from '@tonconnect/sdk';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

const tonConnect = new TonConnect({ walletsListSource:'https://www.dailycashloot.fun/wallets.json'
  ,manifestUrl: `${window.location.origin}/manifest.json`
});
// const tonUI = new TonConnectUIProvider({ walletsListSource:'https://www.dailycashloot.fun/wallets.json'
//   ,manifestUrl: `${window.location.origin}/manifest.json`
// });

export default tonConnect;
