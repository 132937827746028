// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, googleProvider } from '../components/firebaseConfig';
import { getAdditionalUserInfo, onAuthStateChanged } from 'firebase/auth';
import { updateUser } from '../components/firebaseConfig';
import { useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { getDatabase, onValue, ref, set, update } from 'firebase/database';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [UserDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const db = getDatabase()
  const [signInWithGoogle, googleUser, googleLoading, googleError] = useSignInWithGoogle(auth);

  const updatePointsAndHistory = (user, points, description) => {
    const now = new Date().toISOString();
    const userPointsRef = ref(db, `users/${user?.uid}/points`);
    const pointsHistoryRef = ref(db, `users/${user?.uid}/pointsHistory`);
    
    update(userPointsRef, {
      points: user?.points + points,
    });
    
    update(pointsHistoryRef, [
      ...(user.pointsHistory || []),
      { date: now, points, description },
    ]);
  };
  
  const fetchUserDetails = (userId) => {
    const userRef = ref(db, `users/${userId}`);
    let data
    onValue(userRef, (snapshot) => {
      data = snapshot.val();
    });
    return data
  };

  useEffect(() => {
    if(auth?.currentUser){
        setCurrentUser(auth?.currentUser)
        setUserDetails(fetchUserDetails(auth?.currentUser?.uid))
    }
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [googleUser]);

  const getErrorMessage = () => {
    switch (googleError?.code) {
      case 'auth/email-already-in-use':
        return 'This email is already in use. Please use a different email or sign in.';
      case 'auth/invalid-email':
        return 'The email address is invalid. Please enter a valid email address.';
      case 'auth/wrong-password':
        return 'The password is incorrect. Please try again.';
      case 'auth/user-not-found':
        return 'No account found with this email. Please sign up.';
      case 'auth/weak-password':
        return 'The password is too weak. Please use a stronger password.';
      default:
        return googleError?.message || 'An unknown error occurred. Please try again.';
    }
  };

  const signOut = async () => {
    await auth.signOut();
  };

  return (
    <AuthContext.Provider value={{ currentUser,UserDetails, loading,updatePointsAndHistory, fetchUserDetails, signInWithGoogle, signOut, googleUser, googleLoading, googleError, getErrorMessage }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
