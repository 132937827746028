// src/pages/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container min-w-full p-4 bg-white font-sans">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">
        Effective Date: 12-08-2024
      </p>
      <p className="mb-4">
        Welcome to Dailycashloot! This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services, including Firebase, Google AdSense, Web3Onboard, NFT Minting, Task & Earn, Project Launchpad, and Vercel hosting.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">1. Information We Collect</h2>
      <p className="mb-4">
        We may collect personal information such as your name, email address, and other contact details when you sign up or use our services. We also collect information through Firebase Analytics, Firebase Database, and other technologies, including your device information, IP address, and browsing behavior.
      </p>

      <h2 className="text-2xl font-semibold mb-2">2. How We Use Your Information</h2>
      <p className="mb-4">
        We use your information to provide, maintain, and improve our services, including Firebase for analytics and database management, Google AdSense for displaying ads, Web3Onboard for wallet connections, and Vercel for hosting. Your information helps us personalize your experience, communicate with you, and fulfill tasks like NFT minting and participation in Task & Earn or Project Launchpad activities.
      </p>

      <h2 className="text-2xl font-semibold mb-2">3. Firebase Services</h2>
      <p className="mb-4">
        We use Firebase Analytics to monitor and analyze website traffic and user behavior to improve our services. Firebase Database is used to store and manage user data securely. These services may collect device information, usage data, and other relevant information. You can learn more about Firebase’s privacy practices <a href="https://firebase.google.com/support/privacy" className="text-blue-600 hover:underline">here</a>.
      </p>

      <h2 className="text-2xl font-semibold mb-2">4. Google AdSense</h2>
      <p className="mb-4">
        We use Google AdSense to display ads on our website. Google AdSense may collect information about your visits to our website and other websites to provide personalized ads. You can control your ad preferences through your Google account settings or opt out of personalized ads by visiting the Ad Settings page <a href="https://adssettings.google.com/" className="text-blue-600 hover:underline">here</a>.
      </p>

      <h2 className="text-2xl font-semibold mb-2">5. Web3Onboard Wallet Connect</h2>
      <p className="mb-4">
        We use Web3Onboard to enable wallet connections for Web3 interactions, such as NFT minting and transactions. Your wallet address and related blockchain interactions are processed through this service. We do not store your private keys or directly access your wallet.
      </p>

      <h2 className="text-2xl font-semibold mb-2">6. NFT Minting and Blockchain Interactions</h2>
      <p className="mb-4">
        When you participate in NFT minting, we collect and store the necessary information to execute blockchain transactions, such as wallet addresses and transaction details. These interactions are recorded on the blockchain, which is public and immutable.
      </p>

      <h2 className="text-2xl font-semibold mb-2">7. Task & Earn and Project Launchpad</h2>
      <p className="mb-4">
        When you participate in Task & Earn activities or use our Project Launchpad, we collect and process the necessary information to facilitate these interactions. This may include your task submissions, project details, and any associated rewards.
      </p>

      <h2 className="text-2xl font-semibold mb-2">8. Security</h2>
      <p className="mb-4">
        We implement security measures to protect your information from unauthorized access, use, or disclosure. We rely on Firebase and Vercel for secure data storage and hosting. However, no method of transmission over the internet or electronic storage is completely secure.
      </p>

      <h2 className="text-2xl font-semibold mb-2">9. Your Rights</h2>
      <p className="mb-4">
        You have the right to access, correct, or delete your personal information. You may also object to the processing of your data or request that we restrict its use. If you are a resident of the European Economic Area (EEA) or California, you have additional rights under the GDPR and CCPA.
      </p>

      <h2 className="text-2xl font-semibold mb-2">10. Data Retention</h2>
      <p className="mb-4">
        We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as required by law.
      </p>

      <h2 className="text-2xl font-semibold mb-2">11. Children's Privacy</h2>
      <p className="mb-4">
        Our services are not directed to individuals under the age of 13 (or 16 in certain jurisdictions), and we do not knowingly collect personal information from children under this age. If we become aware that we have collected personal information from children under this age, we will take steps to delete the information as soon as possible.
      </p>

      <h2 className="text-2xl font-semibold mb-2">12. Changes to This Privacy Policy</h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.
      </p>

      <h2 className="text-2xl font-semibold mb-2">13. Contact Us</h2>
      <p className="mb-4">
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <a href="mailto:dailycashloot@gmail.com" className="text-blue-500 hover:underline">
              dailycashloot@gmail.com
            </a>. If you are a resident of the EEA or California, and you wish to exercise your rights under GDPR or CCPA, please specify your request in your communication.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
