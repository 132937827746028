import TokenABI from "../contracts/TokenABI.json";
import ReferralABI from "../contracts/ReferralABI.json";
import DCLESPABI from "../contracts/DCLESPABI.json";

export const TokensAddress = "0x37FdFd90EaD06DfcAa5d5989E20B525EFd5cE097";
export const TokensABI = TokenABI;
export const ReferralsAddress = "0x92E69241ff5C4099c790560c8ceCcd9Fee513205";
export const ReferralsABI = ReferralABI;
export const ESPContract = "0x51D2dEe032F8D58004Ac9C52F59485D469c74BCd";
export const ESPABI = DCLESPABI;
