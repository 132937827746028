import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import 'tailwindcss/tailwind.css';

ChartJS.register(ArcElement, Tooltip, Legend);

const Tokenomics = () => {
  const data = {
    labels: [
      'Community & Ecosystem (35%)',
      'Development & Team (20%)',
      'Marketing & Incentives (10%)',
      'Investors & Partners (15%)',
      'Treasury & Reserves (15%)',
      'Liquidity Provisioning (5%)'
    ],
    datasets: [
      {
        data: [35, 20, 10, 15, 15, 5],
        backgroundColor: [
          '#4CAF50',
          '#FFC107',
          '#00BCD4',
          '#FF5722',
          '#9C27B0',
          '#E91E63'
        ],
        borderColor: '#FFFFFF',
        borderWidth: 2,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          color: '#333',
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.label}: ${tooltipItem.raw}%`;
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    animation: {
      duration: 1500,
      easing: 'easeInOutQuart',
    },
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-100 to-gray-300 py-12 px-4">
      
          <h1 className="text-4xl font-bold text-gray-800 mb-6 animate__animated animate__fadeIn">
            CROWN(V2) Tokenomics
          </h1>
          <p className="text-gray-600 mb-8 animate__animated animate__fadeIn animate__delay-1s">
            Understanding the allocation and distribution of CROWN(V2) tokens.
          </p>
          <div className="relative h-96 w-full animate__animated animate__fadeIn animate__delay-2s">
            <Pie data={data} options={options} />
          </div>
    </div>
  );
};

export default Tokenomics;
