import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="container min-w-full p-4 bg-white font-sans">
        <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">Terms and Conditions</h1>

        <div className="mb-4">
          <h2 className="text-2xl font-semibold text-gray-700">1. Introduction</h2>
          <p className="text-gray-600 mt-2">
            Welcome to Dailycashloot! By accessing and using our website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully. If you do not agree with any part of these terms, you must not use our services.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-2xl font-semibold text-gray-700">2. About Dailycashloot</h2>
          <p className="text-gray-600 mt-2">
            Dailycashloot is a platform that offers users access to the latest crypto airdrops, task & earn opportunities, trending decentralized applications (DApps), and other related services. We also provide opportunities for users to participate in giveaways, access promotional content, and engage with community-driven projects.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-2xl font-semibold text-gray-700">3. User Registration and Account Security</h2>
          <p className="text-gray-600 mt-2">
            <strong>Eligibility:</strong> By registering on Dailycashloot, you confirm that you are at least 18 years old and capable of entering into a legally binding agreement.
          </p>
          <p className="text-gray-600 mt-2">
            <strong>Account Creation:</strong> Users must provide accurate and up-to-date information during registration. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
          </p>
          <p className="text-gray-600 mt-2">
            <strong>Referral Program:</strong> Users may participate in our referral program. When registering via a referral link, the referrer’s details will be added to the new user’s account.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-2xl font-semibold text-gray-700">4. User Conduct</h2>
          <p className="text-gray-600 mt-2">
            You agree to use Dailycashloot only for lawful purposes. You must not use our platform to engage in any activity that is fraudulent, harmful, or otherwise inappropriate.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-2xl font-semibold text-gray-700">5. Intellectual Property</h2>
          <p className="text-gray-600 mt-2">
            All content on Dailycashloot, including text, images, logos, and software, is the property of Dailycashloot or its content suppliers and is protected by international copyright laws. Users may not reproduce, distribute, or create derivative works from our content without our express permission.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-2xl font-semibold text-gray-700">6. Limitation of Liability</h2>
          <p className="text-gray-600 mt-2">
            Dailycashloot is not liable for any damages arising from the use of our platform or participation in any of the activities we offer. This includes, but is not limited to, any direct, indirect, incidental, or consequential damages.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-2xl font-semibold text-gray-700">7. Governing Law</h2>
          <p className="text-gray-600 mt-2">
            These terms are governed by and construed in accordance with the laws of India. By using Dailycashloot, you agree to submit to the exclusive jurisdiction of the courts located in India.
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-2xl font-semibold text-gray-700">8. Changes to Terms</h2>
          <p className="text-gray-600 mt-2">
            Dailycashloot reserves the right to modify these Terms and Conditions at any time. We will notify users of any significant changes by posting a notice on our website. Continued use of the platform after such changes constitutes acceptance of the new terms.
          </p>
        </div>

        <div className="mt-6 text-gray-600 text-center">
          <p>
            If you have any questions or concerns about these terms, please contact us at{' '}
            <a href="mailto:dailycashloot@gmail.com" className="text-blue-500 hover:underline">
              dailycashloot@gmail.com
            </a>.
          </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
