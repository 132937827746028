import React from 'react';
import { Chip, Box } from '@mui/material';

// Function to generate random light colors
const getRandomLightColor = () => {
  const r = Math.floor(150 + Math.random() * 105);
  const g = Math.floor(150 + Math.random() * 105);
  const b = Math.floor(150 + Math.random() * 105);
  return `rgb(${r}, ${g}, ${b})`;
};

const Chipview = ({ categories }) => {
  // Split the comma-separated string into an array
  const categoryArray = categories.split(',');

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {categoryArray.map((category, index) => (
        <Chip
          key={index}
          label={category.trim()} // trim to remove any leading/trailing whitespace
          style={{ backgroundColor: getRandomLightColor(), color: 'black' }}
        />
      ))}
    </Box>
  );
};

export default Chipview;
