import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Dashboard from "./components/Dashboard";
import Whitepaper from "./components/Whitepaper";
import Footer from "./components/Footer";
import ReferralContainer from "./components/ReferralContainer";
import PopupDialog from "./components/Modal";
import EarlySupporterNFT from "./components/EarlySupporterNFT";
import {analytics} from './components/firebaseConfig'
import MiningPage from "./components/MiningPage";
import ProjectListingPage from "./pages/ProjectListingPage";
import SubmitProjectPage from "./pages/SubmitProjectPage";
import ProjectDetailsPage from "./pages/ProjectDetailsPage";
import metamaskSDK from "@web3-onboard/metamask";
import bitgetWalletModule from '@web3-onboard/bitget'
import walletConnectModule from '@web3-onboard/walletconnect'
import injectedModule from '@web3-onboard/injected-wallets'
import { init, useConnectWallet } from '@web3-onboard/react';
import 'react-toastify/dist/ReactToastify.css';
import NotFound from "./pages/NotFound";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AuthPage from "./pages/AuthPage";
import TaskPage from "./pages/TaskPage";
import { getAuth } from "firebase/auth";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./pages/AuthContext";
import AdminTaskPage from "./Admin/AdminTaskPage";
import TermsAndConditions from "./policypages/TermsAndConditions";
import PrivacyPolicy from "./policypages/PrivacyPolicy";
import { THEME, TonConnectUIProvider } from "@tonconnect/ui-react";
import tonConnect from "./modules/connecter";
import AdFrame from "./Ads/AdFrame";

const { ethers } = require("ethers");

const auth = getAuth()

const projectIds = process.env.REACT_APP_PROJECTID

const injected = injectedModule()

const bitgetWallet = bitgetWalletModule();

const wcInitOptions = {
  projectId: projectIds,
  requiredChains: [137],
  dappUrl: 'https://www.dailycashloot.fun/'
}

// initialize the module with options
const walletConnect = walletConnectModule(wcInitOptions)

const metamaskSDKWallet = metamaskSDK({
  options: {
    extensionOnly: true,
    dappMetadata: {
      name: "Dailycashloot - Task & Crypto",
      url :"https://www.dailycashloot.fun/"
    },
    defaultReadOnlyChainId: "0x89",
  },
});

const chains = [
  {
    id: '0x89',
    token: 'MATIC',
    label: 'Matic Mainnet',
    rpcUrl: 'https://polygon-rpc.com/',
  },
]

const onboard = init({
  // Other Onboard options.
  wallets: [
    metamaskSDKWallet,
    bitgetWallet,
    injected,
    walletConnect,
    // Other wallets.
  ],
  chains,
  notify: {
    desktop: {
      enabled: true,
      transactionHandler: transaction => {
        console.log({ transaction })
        if (transaction.eventCode === 'txPool') {
          return {
            type: 'success',
            message: 'Your transaction from #1 DApp is in the mempool',
          }
        }
      },
      position: 'bottomRight'
    },
    mobile: {
      enabled: true,
      transactionHandler: transaction => {
        console.log({ transaction })
        if (transaction.eventCode === 'txPool') {
          return {
            type: 'success',
            message: 'Your transaction from #1 DApp is in the mempool',
          }
        }
      },
      position: 'bottomRight'
    }
  },

});

function App() {

  //wallet tracking
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()
  const [ChainID, setChainID] = useState(0);
  //Account SetUp
  const [connectedWallets, setconnectedWallets] = useState(null);
  const [DCLwallet, setDCLWallet] = useState(null);
  const [DCLAccount, setDCLAccount] = useState(0);
  const [account, setAccount] = useState(0);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);

  // Dialog properties
  const [isOpen, setIsOpen] = useState(false);
  const [popupContent, setPopupContent] = useState({
    title: "Invalid Command",
    message: "Something went Wrong",
  });
  

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const showDialog = (_title, _message) => {
    setPopupContent({ title: _title, message: _message });
    openModal();
  };

  // useEffect(() => {
  //   const data = async() => {
  //     const rest = await tonConnect.restoreConnection()
  //     console.log(rest)
  //   }
  //   data()
  //   return () => {
      
  //   };
  // }, []);

  const message = "Dailycashloot Wallet Connect: Signing this message will not trigger any on-chain transaction. This is just to verify your ownership of the wallet.";

  async function signMessage() {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signature = await signer.signMessage(message);
    console.log("Signature:", signature);
    // You can handle the signature as needed
  } catch (error) {
    console.error("Error signing message:", error);
  }
}


const sdkConnect = async() => {
  const connectedWallets = await onboard.connectWallet();
  setconnectedWallets(connectedWallets)
  if (connectedWallets.length > 0) {
    const { accounts, provider } = connectedWallets[0]
    const ethProvider = new ethers.providers.Web3Provider(provider)
    const ethSigner = ethProvider.getSigner(accounts[0]?.address)
    const message = `I acknowledge that I am interacting with the smart contract at address: 0x51D2dEe032F8D58004Ac9C52F59485D469c74BCd. This signature is for authentication purposes only and does not trigger any blockchain transactions or cost any gas fees.`;
    ethSigner.signMessage(message).then((result) => {
      console.log("Successfully Signed")
    }).catch((err) => {
      console.log("Failed", err.message)
    });
    const chainId = await ethProvider.provider.request({ method: 'eth_chainId' });
    if(chainId !== '0x89'){
      console.log('switching chain')
      await switchChain('0x89', accounts[0].address)
      console.log('switched chain')
    }
    setAccount(accounts[0]?.address)
    setProvider(ethProvider)
    setSigner(ethSigner)
  } else {
    console.log('No wallet connected')
  }
}

useEffect(() => {
  const authencity = auth?.currentUser
  if (wallet?.provider) {
    wallet.provider.on('chainChanged', (chainId) => {
      console.log('chainChanged', chainId);
      setChainID(parseInt(chainId, 16));
    });
    wallet?.provider.on('accountsChanged', (accounts) => {
      console.log('accountsChanged', accounts);
      setAccount(accounts[0]);
    });
  }
}, [wallet]);

const addEthereumChain = async () => {
  wallet?.provider
    .request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x89',
          chainName: 'Polygon',
          blockExplorerUrls: ['https://polygonscan.com'],
          nativeCurrency: { symbol: 'MATIC', decimals: 18 },
          rpcUrls: ['https://polygon-rpc.com/'],
        },
      ],
    })
    .then(() => {
      console.log("Success")
    })
    .catch((error) => {
      console.log(`error: `, error);
    });
}

const switchChain = async (hexChainId) => {
  try {
    const result = await onboard.setChain({ chainId: hexChainId })
    console.log(`result: `, result)
  } catch (error) {
    console.log(error);
    if (error.code === -32603 || error.code === 4902) {
    }
  }
}
const Notification = async () => {
  try {
    const noti = onboard?.state.actions.customNotification({
      message:
        'This is a custom DApp success notification to use however you want',
      autoDismiss: 0,
      type: 'pending'
    })
    // console.log(await noti.update({eventCode: 'dbUpdateSuccess',
    //   message: 'Transaction Successfull',
    //   type: 'success',
    //   autoDismiss: 5000}))
  } catch (error) {
    console.log(error)
  }
}


  const requestAccounts = async () => {
    try {
      if(wallet){
        disconnect(wallet)
        setAccount('')
      }else{
        sdkConnect()
      }
    } catch (error) {
      console.log(error)
    }
  };

  

  const propsValues = {
    ChainID,
    account,
    signer,
    provider,
    requestAccounts,
    showDialog,
    connecting,
    wallet,
    connect,
    disconnect,
  };
  
  return (
    <TonConnectUIProvider 
    manifestUrl={`${process.env.NODE_ENV === 'production' ? window.location.origin:'https://www.dailycashloot.fun'}/manifest.json`}
    uiPreferences={{ theme: THEME.DARK }} connector={tonConnect} walletsListConfiguration={{
      includeWallets: [
        {
          "name": "Wallet",
          "appName": "telegram-wallet",
          "imageUrl": "https://wallet.tg/images/logo-288.png",
          "aboutUrl": "https://wallet.tg/",
          "platforms": [
            "ios",
            "android",
            "macos",
            "windows",
            "linux"
          ],
          "bridgeUrl": "https://bridge.ton.space/bridge",
          "universalLink": "https://t.me/wallet?attach=wallet&mode=compact"
        },
        {
          "name": "Tonkeeper",
          "appName": "tonkeeper",
          "imageUrl": "https://tonkeeper.com/assets/tonconnect-icon.png",
          "aboutUrl": "https://tonkeeper.com",
          "tondns": "tonkeeper.ton",
          "platforms": [
            "ios",
            "android",
            "chrome",
            "firefox",
            "macos"
          ],
          "bridgeUrl": "https://bridge.tonapi.io/bridge",
          "universalLink": "https://app.tonkeeper.com/ton-connect",
          "deepLink": "tonkeeper-tc://",
          "jsBridgeKey": "tonkeeper",
          "injected": false,
          "embedded": false
        },
        {
          "name": "MyTonWallet",
          "appName": "mytonwallet",
          "imageUrl": "https://static.mytonwallet.io/icon-256.png",
          "aboutUrl": "https://mytonwallet.io",
          "platforms": [
            "chrome",
            "windows",
            "macos",
            "linux",
            "ios",
            "android",
            "firefox"
          ],
          "jsBridgeKey": "mytonwallet",
          "injected": false,
          "embedded": false,
          "bridgeUrl": "https://tonconnectbridge.mytonwallet.org/bridge/",
          "universalLink": "https://connect.mytonwallet.org"
        },
        {
          "name": "OpenMask",
          "appName": "openmask",
          "imageUrl": "https://raw.githubusercontent.com/OpenProduct/openmask-extension/main/public/openmask-logo-288.png",
          "aboutUrl": "https://www.openmask.app/",
          "platforms": [
            "chrome"
          ],
          "jsBridgeKey": "openmask",
          "injected": true,
          "embedded": false
        },
        {
          "name": "Tonhub",
          "appName": "tonhub",
          "imageUrl": "https://tonhub.com/tonconnect_logo.png",
          "aboutUrl": "https://tonhub.com",
          "platforms": [
            "ios",
            "android"
          ],
          "jsBridgeKey": "tonhub",
          "injected": false,
          "embedded": false,
          "bridgeUrl": "https://connect.tonhubapi.com/tonconnect",
          "universalLink": "https://tonhub.com/ton-connect"
        },
        {
          "name": "DeWallet",
          "appName": "dewallet",
          "imageUrl": "https://raw.githubusercontent.com/delab-team/manifests-images/main/WalletAvatar.png",
          "aboutUrl": "https://delabwallet.com",
          "platforms": [
            "ios",
            "android",
            "macos",
            "windows",
            "linux"
          ],
          "bridgeUrl": "https://bridge.dewallet.pro/bridge",
          "universalLink": "https://t.me/dewallet?attach=wallet"
        },
        {
          "name": "XTONWallet",
          "appName": "xtonwallet",
          "imageUrl": "https://xtonwallet.com/assets/img/icon-256-back.png",
          "aboutUrl": "https://xtonwallet.com",
          "platforms": [
            "chrome",
            "firefox"
          ],
          "jsBridgeKey": "xtonwallet",
          "injected": false,
          "embedded": false
        },
        {
          "name": "TON Wallet",
          "appName": "tonwallet",
          "imageUrl": "https://wallet.ton.org/assets/ui/qr-logo.png",
          "aboutUrl": "https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd",
          "platforms": [
            "chrome"
          ],
          "jsBridgeKey": "tonwallet",
          "injected": true,
          "embedded": false
        },
        {
          "name": "Bitget Wallet",
          "appName": "bitgetTonWallet",
          "imageUrl": "https://raw.githubusercontent.com/bitkeepwallet/download/main/logo/png/bitget_wallet_logo_0_gas_fee.png",
          "aboutUrl": "https://web3.bitget.com",
          "platforms": [
            "ios",
            "android",
            "chrome"
          ],
          "jsBridgeKey": "bitgetTonWallet",
          "injected": false,
          "embedded": false,
          "bridgeUrl": "https://bridge.tonapi.io/bridge",
          "universalLink": "https://bkcode.vip/ton-connect",
          "deepLink": "bitkeep://"
        },
        {
          "name": "SafePal",
          "appName": "safepalwallet",
          "imageUrl": "https://s.pvcliping.com/web/public_image/SafePal_x288.png",
          "aboutUrl": "https://www.safepal.com",
          "tondns": "",
          "platforms": [
            "ios",
            "android",
            "chrome",
            "firefox"
          ],
          "bridgeUrl": "https://ton-bridge.safepal.com/tonbridge/v1/bridge",
          "universalLink": "https://link.safepal.io/ton-connect",
          "deepLink": "safepal-tc://",
          "jsBridgeKey": "safepalwallet",
          "injected": false,
          "embedded": false
        },
        {
          "name": "HOT",
          "appName": "hot",
          "imageUrl": "https://raw.githubusercontent.com/hot-dao/media/main/logo.png",
          "aboutUrl": "https://hot-labs.org/",
          "platforms": [
            "ios",
            "android",
            "macos",
            "windows",
            "linux"
          ],
          "bridgeUrl": "https://sse-bridge.hot-labs.org",
          "universalLink": "https://t.me/herewalletbot?attach=wallet",
          "jsBridgeKey": "hotWallet",
          "injected": false,
          "embedded": false
        }
      ]
    }}
    >
    <div className='bg-gradient-to-br from-gray-800 to-black'>
    <HelmetProvider>
      <BrowserRouter className="App min-w-fit">
      <AuthProvider>
        <Navbar props={propsValues} />
        <Routes>
          <Route exact path="/" element={<Dashboard props={propsValues}/>} />
          <Route exact path="*" element={<NotFound />} />
          <Route
            path="/submit"
            element={
              <ProtectedRoute >
                <SubmitProjectPage />
              </ProtectedRoute>
            }
            />
          <Route
            path="/projects/:projectId"
            element={
              <ProtectedRoute >
                <ProjectDetailsPage />
              </ProtectedRoute>
            }
            />
          <Route
            path="/projects"
            element={
              <ProtectedRoute >
                <ProjectListingPage />
              </ProtectedRoute>
            }
            />
          <Route path="/EarlySupporterPass" element={<EarlySupporterNFT props={propsValues} />} />
          <Route path="/Whitepaper" element={<Whitepaper />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/auth" element={<AuthPage />} />
          <Route
            path="/tasks"
            element={
              <ProtectedRoute>
                <TaskPage />
              </ProtectedRoute>
            }
            />
          {/* <Route
            path="/cryptorecharge"
            element={
              <ProtectedRoute>
                <MobileRecharge props={propsValues}/>
              </ProtectedRoute>
            }
            /> */}
          <Route
            path="/admins/tasks"
            element={
              <ProtectedRoute>
                <AdminTaskPage />
              </ProtectedRoute>
            }
            />
          {/* <Route
            path="/Referrals"
            element={<ReferralContainer props={propsValues} />}
            /> */}
          {/* <Route path="/wallet" element={<WalletPage setDCLWallet={setDCLWallet} DCLwallet={DCLwallet}/>} />
          <Route path="/wallet/backup" element={<BackupMnemonics setDCLWallet={setDCLWallet} DCLwallet={DCLwallet}/>} />
          <Route path="/wallet/dashboard" element={<WalletMain setDCLAccount={setDCLAccount} DCLAccount={DCLAccount}  setDCLWallet={setDCLWallet} DCLwallet={DCLwallet}/>} />
          <Route path="/wallet/dashboard/:tokenName" element={<TokenDetailsPage />} />
          <Route path="/wallet/dashboard/send/:tokenName" element={<SendTokenPage wallet={DCLwallet}/>} />
          <Route path="/wallet/dashboard/receive/:tokenName" element={<ReceiveTokenPage DCLAccount={DCLAccount} />} /> */}
          {/* <Route path="/Tokenomics" element={<Tokenomics />} /> */}
        </Routes>
      <Footer />
      </AuthProvider>
      </BrowserRouter>
      </HelmetProvider>
      <PopupDialog
        isOpen={isOpen}
        onClose={closeModal}
        content={popupContent}
        />
    </div>
        </TonConnectUIProvider>
  );
}

export default App;
